import {Injectable} from '@angular/core';
import * as uuid from 'uuid/v4';
import {BehaviorSubject} from 'rxjs';
import {Folder, List, Offer} from '../constants/interfaces';
import {Variables} from '../constants/variables';
import {TranslateService} from '@ngx-translate/core';
import {InteractionService} from './interaction.service';
import { StorageService } from './storage.service';
import {TrackingService} from './tracking.service';

@Injectable({
  providedIn: 'root'
})
export class ListService {

  readonly listKey = Variables.Storage.Lists;

  private lists: BehaviorSubject<List[]> = new BehaviorSubject([]);

  get getLists() {
    return this.lists;
  }

// jongens wat is dit een crap zooi zeg!

  constructor(private translateService: TranslateService,
              private interactionService: InteractionService,
              private trackingService: TrackingService,
              private storage: StorageService
              ) {
    this.initializeLists();
  }

  private async initializeLists() {
    const allLists = await this.storage.load(this.listKey).toPromise();
    if (allLists) {
      this.lists.next(allLists);
    } else {
      this.saveLists([]);
    }
  }

  getSingle(id: string) {
    const all = this.lists.getValue();
    return all.find(l => l.id === id);
  }

  createList(name: string, id: any = null) {
    if (id === null) {
      id = uuid();
    }
    const all = this.lists.getValue(),
      index = all.findIndex(l => l.name.toLowerCase() === name.toLowerCase());
    if (index === -1) {
      const list = {
        id,
        name,
        keywords: [],
        crossedKeywords: [],
        offers: [],
        offersDone: []
      };
      all.push(list);
      this.saveLists(all);
      return list;
    } else {
      throw Variables.ErrorMessages.ListNameExists;
    }
  }

  editList(list: List) {
    const all = this.lists.getValue(),
      index = all.findIndex(l => l.id === list.id);
    all[index] = list;
    this.saveLists(all);
  }

  addKeyword(keyword: string, currentList: List) {
    if (!keyword) {
      return;
    }
    const keywordIndex = currentList.keywords.findIndex(k => k.toLowerCase() === keyword.toLowerCase());
    if (keywordIndex === -1) {
      currentList.keywords.push(keyword);
      this.editList(currentList);
    }
    keyword = '';
  }

  addKeywordByListId(keyword: string, listId: string, crossed: boolean) {
    if (!keyword) {
      return;
    }

    const list = this.getSingle(listId);
    if (crossed) {
      if (!list.crossedKeywords.includes(keyword)) {
        list.crossedKeywords.push(keyword);
      }
    } else {
      if (!list.keywords.includes(keyword)) {
        list.keywords.push(keyword);
      }
    }

    this.editList(list);
  }

  addOfferByListId(offer: Offer, listId: string) {
    const list = this.getSingle(listId);
    if (list.offers.findIndex(o => o.offer_id === offer.offer_id) === -1 &&
      list.offersDone.findIndex(o => o.offer_id === offer.offer_id) === -1) {
      list.offers.push(offer);
      this.editList(list);
    }
  }

  deleteList(id: string) {
    const all = this.lists.getValue(),
      index = all.findIndex(l => l.id === id);
    if (index !== -1) {
      all.splice(index, 1);
      this.saveLists(all);
    }
  }

  clearOffers(id: string) {
    const all = this.lists.getValue(),
      index = all.findIndex(l => l.id === id);
    if (index !== -1) {
      all[index].offers = [];
      this.saveLists(all);
    }
  }

  clearKeywords(id: string) {
    const all = this.lists.getValue(),
      index = all.findIndex(l => l.id === id);
    if (index !== -1) {
      all[index].keywords = [];
      this.saveLists(all);
    }
  }

  setActive(id: string) {
    const all = this.lists.getValue();
    all.forEach(list => {
      list.active = list.id === id;
    });
    this.saveLists(all);
  }

  async addOfferToList(offer: Offer, folderSession?: string, folder?: Folder) {
    if (this.lists.getValue().length === 0) {
      this.createList(await this.translateService.get('lists.default').toPromise());
    }
    if (this.lists.getValue().length === 1) {
      const list = this.lists.getValue()[0];
      if (this.offerExistsInList(list, offer)) {
        this.removeOfferFromList(list, offer);
      } else {
        this.saveOfferToList(offer, list.id, folderSession, folder);
      }
    } else {
      this.interactionService.showAlert({
        header: 'search.selectList',
        inputs: this.getLists.getValue().map(list => {
          return {
            type: 'radio',
            label: list.name,
            checked: this.offerExistsInList(list, offer),
            handler: () => {
              this.saveOfferToList(offer, list.id, folderSession, folder);
              this.lists.getValue().filter(l => l.id !== list.id).forEach(otherList => {
                this.removeOfferFromList(otherList, offer, false);
              });
              this.interactionService.dismiss();
            }
          };
        }) as any[],
        buttons: [
          {
            text: 'global.cancel',
            role: 'cancel'
          }
        ]
      });
    }
  }

  /**
   * Returns the offer if found in any of the lists, null otherwise.
   *
   * @param offer the offer to find in the existing lists
   */
  getOfferFromLists(offer: Offer): Offer {
    let foundOffer = null;
    this.lists.getValue().forEach((list: List) => {
      list.offers.concat(list.offersDone).forEach((currentOffer: Offer) => {
        if (currentOffer.offer_id === offer.offer_id) {
          foundOffer = offer;
          return;
        }
      });
    });
    return foundOffer;
  }

  private saveOfferToList(offer: Offer, listId: string, folderSession?: string, folder?: Folder) {
    const list = this.getSingle(listId);
    if (list.offers.findIndex(o => o.offer_id === offer.offer_id) === -1) {
      list.offers.push(offer);
    }
    this.editList(list);
    this.interactionService.showToast('search.addedToList');
    this.trackingService.triggerOfferSavedToList(offer, folderSession, folder);
  }

  private saveLists(all: List[]) {
    this.lists.next(all);
    this.storage.save(this.listKey, all);
  }

  private removeOfferFromList(list: List, offer: Offer, showToast: boolean = true) {
    const offerIndex = list.offers.findIndex(o => o.offer_id === offer.offer_id),
      offerDoneIndex = list.offersDone.findIndex(o => o.offer_id === offer.offer_id);
    if (offerIndex !== -1) {
      list.offers.splice(offerIndex, 1);
    }
    if (offerDoneIndex !== -1) {
      list.offersDone.splice(offerDoneIndex, 1);
    }
    this.editList(list);
    if (showToast) {
      this.interactionService.showToast('search.removedFromList');
    }
  }

  private offerExistsInList(list: List, offer: Offer) {
    return list.offers.findIndex(o => o.offer_id === offer.offer_id) >= 0 ||
      list.offersDone.findIndex(o => o.offer_id === offer.offer_id) >= 0;
  }
}
