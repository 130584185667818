import { EventEmitter, Injectable } from '@angular/core';
import {ConfigService} from './config.service';
import {StorageService} from './storage.service';
import {AppProfile} from '../constants/interfaces';
import {ApiService} from './api.service';
import {Variables} from '../constants/variables';
import {Platform} from '@ionic/angular';
import {LocationService} from './location.service';
import * as moment from 'moment';
import * as uuid from 'uuid/v4';
import { Device } from '@ionic-native/device/ngx';
import {SplashScreenService} from './splash-screen.service';
import {NotificationsService} from './notifications.service';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {BehaviorSubject} from 'rxjs';

interface StorageProfile {
  lastUpdate: Date;
  data: AppProfile;
}

@Injectable({
  providedIn: 'root'
})
export class AppProfileService {

  private updateProfileAfterMs: number;

  private profileEndpoint: string;

  private profile: AppProfile;

  readonly profileChanges = new BehaviorSubject({});

  private userSession: string;

  private initialized: boolean = false;
  private initialized$: EventEmitter<void> = new EventEmitter<void>();

  get sessionId() {
    return this.userSession;
  }

  get appProfile() {
    return this.profile;
  }

  constructor(private configService: ConfigService,
              private apiService: ApiService,
              private locationService: LocationService,
              private platform: Platform,
              private appVersion: AppVersion,
              private notificationsService: NotificationsService,
              private device: Device,
              private splashScreenService: SplashScreenService,
              private storageService: StorageService) {
    this.initialize();
  }

  initialize() {
    this.userSession = uuid();
    this.platform.ready()
      .then(async () => {
        this.updateProfileAfterMs = await this.configService.getValue('profile_threshold_milliseconds');
        this.profileEndpoint = await this.configService.getValue('app_resources', 'app_profile_upsert_url');

        let appVersion;
        try {
          appVersion = await this.appVersion.getVersionNumber();
        } catch {
          appVersion = '5.0.0';
        }
        // Get profile from localStorage
        const profile: StorageProfile = await this.storageService.load(Variables.Storage.Profile).toPromise(),
          // Current profile info
          updatedProfile = {
            os_version: this.device.version,
            app_platform: this.device.platform,
            app_version: appVersion,
            device_category: this.platform.is('tablet') ? 'tablet' : 'phone',
            device_id: this.device.uuid,
            device_model: this.device.model
          };

        // Combine updated profile with existing profile
        this.profile = profile ? Object.assign(profile.data ? profile.data : {}, updatedProfile) : updatedProfile;

        if (!this.profile.privacystatement_version) {
          const statementSaved = await this.storageService.load(Variables.Storage.Cookie).toPromise();
          this.profile.privacystatement_version = statementSaved.privacystatement_version;
          this.syncProfile();
        }

        // Listen to location changes
        this.locationService.getLocationChange()
          .subscribe(location => {
            if (location && location.postalCode) {
              this.profile.postal_code = location.postalCode.substring(0, 4);
              this.syncProfile();
            }
          });

        // Listen to Firebase token change
        this.notificationsService.onTokenChange()
          .subscribe(token => {
            this.profile.notification_token_firebase = token ? token : '';
            this.syncProfile();
          });

        // Set timeout for next update
        if (profile) {
          const lastUpdate = moment(profile.lastUpdate),
            now = moment(new Date()),
            difference = moment.duration(now.diff(lastUpdate));
          if (difference.asMilliseconds() > this.updateProfileAfterMs) {
            this.repeatSaveProfile();
          } else {
            setTimeout(() => this.repeatSaveProfile(), this.updateProfileAfterMs - difference.asMilliseconds());
          }
        } else {
          this.repeatSaveProfile();
        }

        this.initialized = true;
        this.initialized$.emit();

      });
  }

  private afterInit(): Promise<void> {
    return new Promise<void>((resolve) => {
      if(this.initialized) {
        resolve();
      }
      else {
        this.initialized$.subscribe(() => {
          resolve();
        });
      }
    });
  }

  private async syncProfile() {
    if (!this.profile.device_id) {
      return;
    }
    try {
      await this.apiService.syncProfile(this.profileEndpoint, this.profile).toPromise();
      await this.saveProfile();
    } catch {
      console.log('Could not sync your profile: ' + JSON.stringify(this.profile));
    }
  }

  private async saveProfile() {
    this.profileChanges.next(this.profile);
    await this.storageService.save(Variables.Storage.Profile, {
      lastUpdate: new Date(),
      data: this.profile
    });
  }

  private repeatSaveProfile() {
    this.syncProfile();
    setTimeout(() => this.repeatSaveProfile(), this.updateProfileAfterMs);
  }

  reInitSession() {
    this.userSession = uuid();
    this.splashScreenService.show();
  }

  async toggleNotifications(set?: boolean) {
    try {
      this.profile.notifications_enabled = set === undefined ? !this.profile.notifications_enabled : set;
      return this.syncProfile();
    } catch {
      return Promise.reject();
    }
  }

  async setProfileProperty(key: string, value: any) {
    await this.afterInit();
    this.profile[key] = value;
    this.saveProfile();
  }
}
