import { Component, Input } from '@angular/core';
import { Offer } from '../../../constants/interfaces';

@Component({
  selector: 'app-offer-row',
  templateUrl: './offer-row.component.html',
  styleUrls: ['./offer-row.component.scss'],
})
export class OfferRowComponent  {

  @Input() offers: Offer[];

  constructor() {
  }

}
