import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {ImageSizeModule} from '../../pipes/image-size/image-size.module';
import {OfferHighlightedComponent} from './offer-highlighted.component';
import {EuroModule} from '../../pipes/euro/euro.module';
import { LazyloadModule } from '../../directives/lazyload/lazyload.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule.forChild(),
        ImageSizeModule,
        EuroModule,
        LazyloadModule
    ],
    declarations: [OfferHighlightedComponent],
    exports: [OfferHighlightedComponent]
})
export class OfferHighlightedModule {
}
