import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CustomIconBtnComponent } from './custom-icon-btn.component';
import { CustomIconModule } from '../custom-icon/custom-icon.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    CustomIconModule
  ],
  declarations: [CustomIconBtnComponent],
  exports: [CustomIconBtnComponent]
})
export class CustomIconBtnModule {
}
