import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {CustomIconComponent} from './custom-icon.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule
    ],
    declarations: [CustomIconComponent],
    exports: [CustomIconComponent]
})
export class CustomIconModule {
}
