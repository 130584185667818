import { Component, Input } from '@angular/core';
import { Store } from '../../../constants/interfaces';

@Component({
  selector: 'app-store-carousel',
  templateUrl: './store-carousel.component.html',
  styleUrls: ['./store-carousel.component.scss'],
})
export class StoreCarouselComponent {

  @Input()
  stores: Store[];

  constructor() { }

  clickedStore(store: Store) {
    const addressParams = [store.address, store.postal_code, store.city];
    const query = addressParams.join(' ');
    window.open('https://www.google.com/maps/search/?api=1&query=' + encodeURI(query), '_system');
  }
}
