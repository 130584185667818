import {Injectable} from '@angular/core';
import {
  RealmCustomerCard,
  RealmFavoriteRetailer,
  RealmFolderRead,
  RealmSearchQuery, RealmShoppingList, RealmSubscribedRetailer, RealmUserLocation
} from '../constants/realm.interfaces';
import {LocationService} from './location.service';
import {ListService} from './list.service';
import {SearchService} from './search.service';
import * as moment from 'moment';
import {Variables} from '../constants/variables';
import {StorageService} from './storage.service';
import {CustomerCard, Retailer} from '../constants/interfaces';
import {FolderService} from './folder.service';
import {FavoriteRetailersService} from './favorite-retailers.service';
import {ApiService} from './api.service';
import {PreferencesService} from './preferences.service';
import {NotificationsRetailerService} from './notifications-retailer.service';

declare var cordova: any;

@Injectable({
  providedIn: 'root'
})
export class RealmService {

  constructor(private locationService: LocationService,
              private listService: ListService,
              private searchService: SearchService,
              private storageService: StorageService,
              private retailerNotificationsService: NotificationsRetailerService,
              private folderService: FolderService,
              private apiService: ApiService,
              private preferencesService: PreferencesService,
              private favoriteRetailerService: FavoriteRetailersService) {
  }

  initializeDatabase() {
    return new Promise((resolve, reject) => cordova.plugins.CordovaRealm.initializeDatabase(resolve, reject));
  }

  async restoreFavoriteRetailers() {
    try {
      const hasSynced = await this.storageService.load(Variables.Storage.SyncFavoriteRetailers).toPromise();
      if (hasSynced === null) {
        const data: RealmFavoriteRetailer[] = await this.getData('FavoriteRetailer');
        const favorites: Retailer[] = [];
        for (const item of data) {
          const favorite = await this.apiService.getRetailer(item.retailerId).toPromise();
          favorites.push(favorite);
        }
        this.favoriteRetailerService.addToFavorites(favorites, false);
        this.storageService.save(Variables.Storage.SyncFavoriteRetailers, true);
      }
    } catch {
      console.log('Could not restore favorite retailers');
    }
  }

  async restoreFoldersRead() {
    try {
      const hasSynced = await this.storageService.load(Variables.Storage.SyncFoldersRead).toPromise();
      if (hasSynced === null) {
        const data: RealmFolderRead[] = await this.getData('FolderRead');
        data.forEach(folder => {
          this.folderService.addReadFolder({
            folder_edition_id: folder.folderEditionId,
            folder_version_id: folder.folderVersionId,
            category_id: folder.retailerCategoryId,
            retailer_id: folder.retailerCategoryId
          }, moment(folder.readDate).toDate());
        });
        this.storageService.save(Variables.Storage.SyncFoldersRead, true);
      }
    } catch {
      console.log('Could not restore read folders');
    }
  }

  async restoreCustomerCards() {
    try {
      const hasSynced = await this.storageService.load(Variables.Storage.SyncCustomerCards).toPromise();
      if (hasSynced === null) {
        const data: RealmCustomerCard[] = await this.getData('LoyaltyCard');
        const cards: CustomerCard[] = [];
        data.forEach(card => {
          cards.push({
            id: card.id,
            code: card.barcode,
            retailer_id: null,
            retailer_name: card.retailerName,
            retailer_permaname: null,
            retailer_image: card.retailerImageUrl,
            retailer_color: card.retailerColor,
            category_id: null,
            category_name: card.categoryName,
            last_used: moment(card.openDate).toDate()
          });
        });
        this.storageService.save(Variables.Storage.CustomerCards, cards);
        this.storageService.save(Variables.Storage.SyncCustomerCards, true);
      }
    } catch {
      console.log('Could not restore customer cards');
    }
  }

  async restoreSearchQueries() {
    try {
      const hasSynced = await this.storageService.load(Variables.Storage.SyncSearchQueries).toPromise();
      if (hasSynced === null) {
        const data: RealmSearchQuery[] = await this.getData('RecentQuery');
        data.forEach(search => this.searchService.saveKeyword(search.text));
        this.storageService.save(Variables.Storage.SyncSearchQueries, true);
      }
    } catch {
      console.log('Could not restore recent search queries');
    }
  }

  async restoreLists() {
    try {
      const hasSynced = await this.storageService.load(Variables.Storage.SyncPersonalLists).toPromise();
      if (hasSynced === null) {
        const data: RealmShoppingList[] = await this.getData('ShoppingListSave');
        data.forEach(list => {
          try {
            const currentList = this.listService.createList(list.name, list.id);
            list.queries.forEach(keyword => this.listService.addKeyword(keyword.text, currentList));
            list.offers.forEach(offer => {
              this.listService.addOfferByListId({
                offer_id: offer.offerId,
                assets: {
                  offer_image: {
                    image_url: offer.offerImageUrl
                  }
                },
                title: offer.title,
                sub_title: offer.subtitle,
                retailer_id: offer.retailerId,
                retailer_name: offer.retailerName,
                normal_price: offer.normalPrice,
                offer_price: offer.offerPrice,
                valid_label_short: offer.validLabelShort,
                valid_label_long: offer.validLabelLong,
                valid_thru: offer.validThru
              }, currentList.id);
            });
          } catch {
            console.log('Could not add new shopping list');
          }
        });
        this.storageService.save(Variables.Storage.SyncPersonalLists, true);
      }
    } catch {
      console.log('Could not restore shopping list');
    }
  }

  async restoreSubscribedRetailers() {
    try {
      const hasSynced = await this.storageService.load(Variables.Storage.SyncSubscribedRetailers).toPromise();
      if (hasSynced === null) {
        const data: RealmSubscribedRetailer[] = await this.getData('SubscribedRetailer');
        const retailers: Retailer[] = [];
        for (const retailer of data) {
          const retailerData = await this.apiService.getRetailer(retailer.retailerId).toPromise();
          retailers.push(retailerData);
        }
        this.retailerNotificationsService.addToNotifications(retailers, false);
        this.storageService.save(Variables.Storage.SyncSubscribedRetailers, true);
      }
    } catch {
      console.log('Could not restore subscribed retailers');
    }
  }

  async restoreUserLocation() {
    try {
      const hasSynced = await this.storageService.load(Variables.Storage.SyncUserLocation).toPromise();
      if (hasSynced === null) {
        const data: RealmUserLocation[] = await this.getData('UserLocation');
        const currentPostalCode = data.length ? data[0].postalCode : null;
        if (currentPostalCode) {
          this.locationService.getLocationFromPostalCode(currentPostalCode).then((location) => {
            this.locationService.saveLocation(location, 'manual');
          });
        }
        this.storageService.save(Variables.Storage.SyncUserLocation, true);
      }
    } catch {
      console.log('Could not restore user location');
    }
  }

  private getData(key: string): Promise<any[]> {
    return new Promise((resolve, reject) => cordova.plugins.CordovaRealm.getData(key, resolve, reject));
  }

  crash() {
    this.getData('Dussuh').then(console.log).catch(console.log);
  }
}
