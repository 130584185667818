import { Component, Input, OnInit } from '@angular/core';
import { ScreenSection, TextItem } from '../../../constants/interfaces';

@Component({
  selector: 'app-custom-text',
  templateUrl: './custom-text.component.html',
  styleUrls: ['./custom-text.component.scss'],
})
export class CustomTextComponent implements OnInit {

  @Input()
  textItem: TextItem;

  @Input()
  section: ScreenSection;

  constructor() { }

  ngOnInit() {}

}
