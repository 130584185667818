import { Directive, ElementRef, Host, HostBinding, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { delay, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { fromEvent, Observable, Subject, } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appProgressiveImg]',
})
export class ProgressiveImgDirective implements OnInit, OnChanges {

  @Input('appProgressiveImg') readonly imageUrl: string;

  @Input() lowResSize: string = '96';
  @Input() highResSize: string = '1536';
  @Input() loadHighRes: boolean = true;
  @Input() loadHighResTimeout: number = 150;
  @HostBinding('src') srcAttr;

  private _loadHighRes$: Subject<void> = new Subject();

  constructor(@Inject(DOCUMENT) private _document,
              @Host() private imgElem: ElementRef) {}

  private get lowResUrl(): string {
    return `${this.imageUrl}?size=${this.lowResSize}`;
  }

  private get highResUrl(): string {
    return `${this.imageUrl}?size=${this.highResSize}`;
  }

  ngOnInit(): void {
    if (! this.imageUrl) return;

    this.srcAttr = this.lowResUrl;

    this._loadHighRes$.pipe(
      take(1),
      switchMap(() => this._loadHighRes())
    ).subscribe();

    if(this.loadHighRes) {
      this._loadHighRes$.next();
    }

  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadHighRes && changes.loadHighRes.currentValue) {
      this._loadHighRes$.next();
    }
  }


  private _loadHighRes(): Observable<void> {

    const highResImgElem = this._document.createElement('img');
    const onHighResImgLoaded$ = fromEvent(highResImgElem, 'load');

    const replaceImageAfterLoaded$ = onHighResImgLoaded$.pipe(
      take(1),
      delay(this.loadHighResTimeout),
      tap(() => {
        this.imgElem.nativeElement.parentNode.replaceChild(highResImgElem, this.imgElem.nativeElement);
      }),
      mapTo(void 0)
    );

    highResImgElem.src = this.highResUrl;
    return replaceImageAfterLoaded$;

  }

}
