import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {Subscription} from 'rxjs';
import {IonContent, ModalController} from '@ionic/angular';
import {Folder, Offer, ScreenSection} from '../../constants/interfaces';
import {Variables} from '../../constants/variables';
import {ApiService} from '../../services/api.service';
import {TouchService} from '../../services/touch.service';

@Component({
  selector: 'app-offer-modal',
  templateUrl: './offer-modal.component.html',
  styleUrls: ['./offer-modal.component.scss'],
})
export class OfferModalComponent implements OnInit, OnDestroy {

  @ViewChild(IonContent) content: any;

  @Input() offer: Offer;

  screenSections: ScreenSection[] = [];

  folderSession?: string;
  folder?: Folder;

  onShare: () => void;

  errorStatusCode: number = null;

  loaded = false;

  private scrollTop = true;

  private screenSubscription: Subscription;

  readonly sectionTypes = Variables.ScreenSections;

  private onSwipeDownSubscription: Subscription;
  private imageZoomedOut: boolean = true;


  constructor(private translateService: TranslateService,
              private apiService: ApiService,
              private modalCtrl: ModalController,
              private socialSharing: SocialSharing,
              private touchService: TouchService) {
  }

  ngOnInit() {
    this.getScreen();
  }

  ngOnDestroy(): void {
    if (this.onSwipeDownSubscription) {
      this.onSwipeDownSubscription.unsubscribe();
    }
  }

  getScreen() {
    this.loaded = false;
    this.screenSubscription = this.apiService.getDetailScreenSections(Variables.Api.OffersScreens, this.offer.offer_id)
      .subscribe(screenSections => {
        this.screenSections = screenSections;
        this.errorStatusCode = null;
        this.touchService.setTargetElement(this.content);
        this.onSwipeDownSubscription = this.touchService.onSwipeDown.subscribe(() => {
          if (this.scrollTop && this.imageZoomedOut) {
            this.modalCtrl.dismiss();
          }
        });

        setTimeout(() => {
          this.loaded = true;
        }, 200); // timeout to allow for components to render nicely
      }, error => {
        this.loaded = true;
        this.errorStatusCode = error.status;
      });
  }

  async share() {
    this.translateService.get(['global.share', 'offer.shareTitle', 'offer.shareText'], {
      retailerName: this.offer.retailer_name
    })
      .subscribe(async (shareTranslation: string) => {
        const shareParams = {
          title: shareTranslation['offer.shareTitle'],
          text: `${shareTranslation['offer.shareTitle']}\n${shareTranslation['offer.shareText']}`,
          url: this.offer.share_url,
          dialogTitle: shareTranslation['global.share']
        };

        try {
          await this.socialSharing.share(shareParams.text, shareParams.title, null, shareParams.url);
          if (this.onShare) {
            this.onShare();
          }
        } catch (e) {
          console.log('Share not available');
        }
      });
  }

  onSwipeDownHeader($event) {
    this.modalCtrl.dismiss();
  }

  scroll($event) {
    if ($event.detail.scrollTop === 0) {
      this.scrollTop = true;
    } else {
      this.scrollTop = false;
    }
  }

  imageEvents($event) {
    if ($event.type === 'zoom-out') {
      this.imageZoomedOut = true;
    } else if ($event.type === 'zoom-in') {
      this.imageZoomedOut = false;
    }
    if ($event.moveY > 100) {
      this.modalCtrl.dismiss();
    }
  }
}
