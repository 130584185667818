import { Directive, ElementRef, Host, HostBinding, Input, Optional } from '@angular/core';
import { take } from 'rxjs/operators';
import { IonImg } from '@ionic/angular';
import { fromEvent, Observable } from 'rxjs';

@Directive({
  selector: '[appLazyLoad]',
})
export class LazyloadDirective {

  @HostBinding('class.lazyload') lazyLoadClass = true;

  @Input() @HostBinding('class.show-background') showBackground: boolean = false;

  constructor(@Optional() @Host() imgElem: ElementRef, @Optional() @Host() ionImgElem: IonImg) {

    let onLoad$: Observable<Event>,
        onError$: Observable<Event>;

    if (ionImgElem) {
      // <ion-img>
      onLoad$ = ionImgElem.ionImgDidLoad;
      onError$ = ionImgElem.ionError;

    } else {
      // Regular <img>
      onLoad$ = fromEvent(imgElem.nativeElement, 'load');
      onError$ = fromEvent(imgElem.nativeElement, 'error');
    }

    const onImgLoad = (ev) => {
      const target = ev.target as HTMLElement;
      setTimeout(() => target.classList.add('loaded'), 100);
    };
    const ongImgError = (ev) => (ev.target as HTMLImageElement).src = './assets/images/logo.jpg';

    onLoad$.pipe(take(1)).subscribe(onImgLoad);
    onError$.pipe(take(1)).subscribe(ongImgError);

  }

}
