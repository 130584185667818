import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TextContrastService {

    shouldBeLightText(bgColor: string) {
        if (!bgColor) {
            bgColor = 'FFFFFF';
        }
        const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
        const l = color.length;
        const r = parseInt(color.substring(0, l / 3), 16); // hexToR
        const g = parseInt(color.substring(l / 3, (l / 3) * 2), 16); // hexToG
        const b = parseInt(color.substring((l / 3) * 2, l), 16); // hexToB
        return r * 0.299 + g * 0.587 + b * 0.114 <= 186;
    }

}
