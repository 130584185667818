import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {AnyKey} from '../constants/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _configPromise: Promise<any>;

  private _configValues: AnyKey;

  constructor(private apiService: ApiService) {
  }

  async getValue(...propertyPath: string[]) {
    try {
      let config;
      if (this._configValues) {
        config = this._configValues;
      } else if (this._configPromise) {
        config = (await this._configPromise).config;
      } else {
        this._configPromise = this.apiService.getAppConfig().toPromise();
        this._configValues = (await this._configPromise).config;
        config = this._configValues;
      }

      propertyPath.forEach(prop => config = config[prop]);
      return config;
    } catch (e) {
      this._configPromise = null;
      return Promise.reject(e);
    }
  }
}
