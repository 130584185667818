import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {AdvertisementComponent} from './advertisement.component';
import { AdvertisementServiceModule } from '../../services/advertisement.service.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        AdvertisementServiceModule,
    ],
    declarations: [AdvertisementComponent],
    exports: [AdvertisementComponent]
})
export class AdvertisementModule {
}
