import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {OfferHighlightedModule} from '../../offer-highlighted/offer-highlighted.module';
import { OfferDetailSectionComponent } from './offer-detail-section.component';
import { EuroModule } from '../../../pipes/euro/euro.module';
import { TranslateModule } from '@ngx-translate/core';
import { CustomIconBtnModule } from '../../custom-icon-btn/custom-icon-btn.module';
import { ImageSizeModule } from '../../../pipes/image-size/image-size.module';
import { PinchZoomModule } from '../../pinch-zoom/pinch-zoom.module';
import { ProgressiveImgModule } from '../../../directives/progressive-img/progressive-img.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        EuroModule,
        ImageSizeModule,
        TranslateModule.forChild(),
        CustomIconBtnModule,
        OfferHighlightedModule,
        PinchZoomModule,
        ProgressiveImgModule
    ],
    declarations: [OfferDetailSectionComponent],
    exports: [OfferDetailSectionComponent]
})
export class OfferDetailSectionModule {
}
