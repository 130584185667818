import { Component, Input } from '@angular/core';
import { Offer } from '../../../constants/interfaces';

@Component({
  selector: 'app-offer-carousel',
  templateUrl: './offer-carousel.component.html',
  styleUrls: ['./offer-carousel.component.scss'],
})
export class OfferCarouselComponent {

  @Input() offers: Offer[];

  @Input() inOfferModal: boolean = false;

  @Input() safePadding: boolean = false;

  constructor() {
  }


}
