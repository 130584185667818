import {Injectable} from '@angular/core';
import {SplashScreenComponent} from '../components/splash-screen/splash-screen.component';
import {ModalController} from '@ionic/angular';
import {BehaviorSubject} from 'rxjs';
import { StorageService } from './storage.service';
import { Variables } from '../constants/variables';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {

  readonly onDismiss = new BehaviorSubject(false);

  constructor(private modalCtrl: ModalController,
              private storageService: StorageService) {
  }

  public async shouldShowSplashScreen() {
    return await this.storageService.load(Variables.Storage.ShowSplashScreen).toPromise() !== null;
  }

  async show() {
    if (!environment.production) {
      return;
    }
    const splashScreenModal = await this.modalCtrl.create({
      id: 'splash-screen',
      cssClass: 'modal-not-rounded modal-on-top modal-full-screen',
      component: SplashScreenComponent,
      componentProps: {
        onDismiss: () => this.onDismiss.next(true)
      },
      animated: false,
      backdropDismiss: false,
      leaveAnimation: (animation, base) => {
        return Promise.resolve(new animation()
          .addElement(base)
          .fromTo('opacity', 1, 0)
          .duration(500)
          .addElement(base.querySelector('ion-backdrop'))
          .addElement(base.querySelector('.modal-wrapper')));
      }
    });
    // TODO: Enable (temporary disabled for dev)
    await splashScreenModal.present();
    splashScreenModal.animated = true;
  }
}
