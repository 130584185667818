import { Component, OnInit } from '@angular/core';
import { CookieService } from '../../services/cookie.service';
import { ModalController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-cookie-wall',
  templateUrl: './cookie-wall.component.html',
  styleUrls: ['./cookie-wall.component.scss'],
})
export class CookieWallComponent implements OnInit {

  constructor(public cookieService: CookieService,
              private statusBar: StatusBar,
              private modalCtrl: ModalController,
              private inAppBrowser: InAppBrowser) { }

  ngOnInit() {}

  saveCookie() {
    this.statusBar.show();
    this.cookieService.saveCookie();
    this.modalCtrl.dismiss();
  }

  navigateByUrl(route) {
    this.inAppBrowser.create(route, '_system');
  }
}
