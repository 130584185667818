import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {List, Offer} from '../../constants/interfaces';
import {IonItemSliding} from '@ionic/angular';
import {SearchService} from '../../services/search.service';
import {OfferService} from '../../services/offer.service';
import {ListService} from '../../services/list.service';
import {InteractionService} from '../../services/interaction.service';

@Component({
  selector: 'app-offer-horizontal',
  templateUrl: './offer-horizontal.component.html',
  styleUrls: ['./offer-horizontal.component.scss'],
})
export class OfferHorizontalComponent implements OnInit, OnChanges {

  @Input()
  offer: Offer;

  @Input()
  lastInRow: boolean;

  @Input()
  preselectedList: List;

  @Output()
  onOfferOpened = new EventEmitter();

  @Output()
  onDrag = new EventEmitter();

  offerInList: boolean;
  offerCrossedInList: boolean;

  constructor(private searchService: SearchService,
              private listService: ListService,
              private interactionService: InteractionService,
              private offerService: OfferService) {
  }

  ngOnInit(): void {
    this.syncOffersInListStats();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.syncOffersInListStats();
  }

  async saveInList(itemSlider: IonItemSliding) {
    await itemSlider.close();
    if (this.preselectedList) {
      if (this.preselectedList.offers.findIndex(o => o.offer_id === this.offer.offer_id) === -1) {
        this.preselectedList.offers.push(this.offer);
        this.listService.editList(this.preselectedList);
        this.interactionService.showToast('search.addedToList');
      } else {
        this.interactionService.showToast('lists.alreadyInList');
      }
    } else {
      this.listService.addOfferToList(this.offer);
    }
  }

  async openOfferDetails() {
    this.onOfferOpened.emit();
    await this.offerService.showOfferDetails(this.offer);
  }

  async checkInList(slider: IonItemSliding) {
    await slider.close();
    this.preselectedList.offers.splice(this.preselectedList.offers.findIndex(o => o.offer_id === this.offer.offer_id), 1);
    this.preselectedList.offersDone.push(this.offer);
    this.syncOffersInListStats();
    this.listService.editList(this.preselectedList);
  }

  async uncheckInList(slider: IonItemSliding) {
    await slider.close();
    this.preselectedList.offersDone.splice(this.preselectedList.offersDone.findIndex(o => o.offer_id === this.offer.offer_id), 1);
    this.preselectedList.offers.push(this.offer);
    this.syncOffersInListStats();
    this.listService.editList(this.preselectedList);
  }

  async removeFromList(slider: IonItemSliding, activeInList: boolean) {
    await slider.close();
    if (activeInList) {
      this.preselectedList.offers.splice(this.preselectedList.offers.findIndex(o => o.offer_id === this.offer.offer_id), 1);
    } else {
      this.preselectedList.offersDone.splice(this.preselectedList.offersDone.findIndex(o => o.offer_id === this.offer.offer_id), 1);
    }
    this.syncOffersInListStats();
    this.listService.editList(this.preselectedList);
  }

  private syncOffersInListStats() {
    if (this.preselectedList) {
      this.offerInList = this.preselectedList.offers.findIndex(o => o.offer_id === this.offer.offer_id) !== -1;
      this.offerCrossedInList = this.preselectedList.offersDone.indexOf(this.offer) >= 0;
    }
  }
}
