import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GoogleMapsComponent} from '../../components/google-maps/google-maps.component';
import {Variables} from '../../constants/variables';
import {StorageService} from '../../services/storage.service';
import {LocationService} from '../../services/location.service';
import {Platform} from '@ionic/angular';
import {TrackingService} from '../../services/tracking.service';
import {InteractionService} from '../../services/interaction.service';
import {OpenNativeSettings} from '@ionic-native/open-native-settings/ngx';
import {Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {Folder} from '../../constants/interfaces';
import {filter, take} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-location',
  templateUrl: './location.page.html',
  styleUrls: ['./location.page.scss'],
})
export class LocationPage implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('googleMaps')
  googleMaps: GoogleMapsComponent;

  @ViewChild('backButton')
  private backButton: any;

  postalCode: string;
  numericKeyboard = true;

  private userHasOpenedLocationSettings: boolean = false;
  private folderEditionId: number;
  retrievingLocation: boolean = false;

  readonly pages = Variables.Pages;

  private locationSubscription: Subscription;

  constructor(private storageService: StorageService,
              private trackingService: TrackingService,
              private locationService: LocationService,
              private platform: Platform,
              private interactionService: InteractionService,
              private openNativeSettings: OpenNativeSettings,
              private router: Router,
              private apiService: ApiService) {
    const navigationExtras = this.router.getCurrentNavigation().extras;
    if (navigationExtras) {
      this.folderEditionId = navigationExtras.state ? navigationExtras.state.open_folder_edition_id : null;
    }
  }

  async ngOnInit() {
    this.postalCode = null;
  }

  ngAfterViewInit() {
    this.locationSubscription = this.locationService.getLocationChange()
      .pipe(
        filter(location => !!location),
        take(1)
      )
      .subscribe(location => {
        if (location) {
          this.numericKeyboard = false;
          this.postalCode = location.postalCode;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }

  async onPostalcodeChange() {
    const postalEmpty = !this.postalCode || this.postalCode.length === 0;
    this.numericKeyboard = postalEmpty || this.postalCode.length < 4;

    if (this.postalCodeIsValid()) {
      try {
        const retrievedLocation = await this.locationService.getLocationFromPostalCode(this.postalCode);
        this.googleMaps.setLocation(retrievedLocation);
      } catch {
        this.showInvalidLocationAlert('postalCode');
      }
    } else if (this.postalCode.length === 0) {
      this.setLocation(false);
    }
  }

  postalCodeIsValid() {
    const postalCodeRegExp = new RegExp('^[1-9][0-9]{3}[a-zA-Z]{2}$');
    return this.postalCode ? this.postalCode.length === 6 && postalCodeRegExp.test(this.postalCode) : false;
  }

  async getCurrentLocation() {
    try {
      this.retrievingLocation = true;
      this.numericKeyboard = false;
      const retrievedLocation = await this.locationService.getLocationFromGps();
      this.postalCode = retrievedLocation.postalCode;
      this.googleMaps.setLocation(retrievedLocation);
      this.retrievingLocation = false;

    } catch (e) {
      // Retrieving GPS location failed
      this.retrievingLocation = false;

      if (this.userHasOpenedLocationSettings) {
        this.showLocationFailedAlert();
      } else {
        this.showEnableLocationAlert();
      }

      this.googleMaps.setLocation(Variables.Locations.Netherlands);
    }
  }

  async setLocation(goBack: boolean = true) {
    if (!this.postalCode || this.postalCode.length === 0) {
      this.locationService.saveLocation(null);
      this.googleMaps.setLocation(Variables.Locations.Netherlands, false);
    } else {
      try {
        const retrievedLocation = await this.locationService.getLocationFromPostalCode(this.postalCode);
        this.googleMaps.setLocation(retrievedLocation);
        await this.locationService.saveLocation(retrievedLocation, 'manual');
      } catch (e) {
        this.showInvalidLocationAlert('postalCode');
        return;
      }
    }
    this.trackingService.triggerSaveLocation(this.postalCode);
    if (goBack) {
      if (this.folderEditionId) {
        this.apiService.getFolderEdition(this.folderEditionId).subscribe((response: Folder) => {
          this.router.navigate([this.pages.Folder, response.folder_version_id]);
        });
      } else {
        this.backButton.el.click();
      }
    }
  }


  async setGoogleMapsLocation() {
    const savedLocation = await this.storageService.load(Variables.Storage.Location).toPromise();

    if (savedLocation) {
      this.postalCode = savedLocation.postalCode;
      this.googleMaps.setLocation(savedLocation);
    } else {
      // Center of The Netherlands
      this.googleMaps.setLocation(Variables.Locations.Netherlands, false);
    }
  }

  showEnableLocationAlert() {
    this.interactionService.showAlert({
      header: 'global.oops',
      subHeader: 'pages.onboarding.location.enableLocation',
      buttons: [{
        text: 'global.ok',
        handler: () => {
          this.userHasOpenedLocationSettings = true;
          this.openNativeSettings.open('application_details');
        }
      },
        {
          text: 'global.cancel',
          handler: () => {
            this.interactionService.dismiss();
          }
        }]
    });
  }

  showLocationFailedAlert() {
    this.interactionService.showAlert({
      header: 'global.oops',
      subHeader: 'pages.onboarding.actions.noLocation',
      buttons: [{
        text: 'global.ok',
        handler: () => {
          this.userHasOpenedLocationSettings = false;
        }
      }]
    });
  }

  showInvalidLocationAlert(type: 'postalCode' | 'coordinates') {
    this.interactionService.showAlert({
      header: 'global.oops',
      subHeader: (type === 'postalCode') ? 'location.oopsWithPostalCode' : 'location.oopsWithCoordinates',
      buttons: [{
        text: 'global.ok'
      }]
    });
  }

}
