import {
  BehaviorSubject,
  from,
  Observable,
  Subject,
} from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

type ObservableFn<T> = () => Observable<T>;

export const APP_LIFECYCLE = {

  triggers: {},

  trigger: (event: string) => {
    // console.log(`APP_LIFECYCLE.trigger ${event}`);
    APP_LIFECYCLE.getTrigger(event).next(true);
  },

  after: async (event: string): Promise<void> => {
    // console.log(`APP_LIFECYCLE.after ${event}`);
    return APP_LIFECYCLE.getTrigger(event).pipe(
      filter(v => v),
      first(),
      map(() => null)
    ).toPromise();
  },

  after$: <T>(event: string, innerObservable: ObservableFn<T>): Observable<T> => {
    return from(APP_LIFECYCLE.after(event)).pipe(
      tap(() => {console.log(`${event} now`);}),
      switchMap(innerObservable)
    );
  },

  getTrigger(event: string): Subject<boolean> {
    if (! APP_LIFECYCLE.triggers.hasOwnProperty(event)) {
      APP_LIFECYCLE.triggers[event] = new BehaviorSubject<boolean>(false);
      // console.log(`Created trigger ${event}`);
    }
    else {
      // console.log(`Existing trigger ${event}`);
    }
    return APP_LIFECYCLE.triggers[event];
  }


};





