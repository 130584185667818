import {Component, Input, OnInit} from '@angular/core';
import {Offer} from '../../constants/interfaces';
import {PlaceholderService} from '../../services/placeholder.service';
import { OfferService } from '../../services/offer.service';

@Component({
    selector: 'app-offer-highlighted',
    templateUrl: './offer-highlighted.component.html',
    styleUrls: ['./offer-highlighted.component.scss'],
})
export class OfferHighlightedComponent {

    @Input()
    offer: Offer;

    @Input()
    simplified: boolean;

    constructor(private offerService: OfferService) {
    }

    async openOfferDetails() {
        await this.offerService.showOfferDetails(this.offer);
    }
}
