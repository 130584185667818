import {NgModule} from '@angular/core';
import {DfpModule} from 'ngx-dfp';

@NgModule({
    imports: [
    ],
    providers: [
    ],
    declarations: [],
    exports: [DfpModule]
})
export class AdvertisementServiceModule {
}
