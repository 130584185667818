import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-advertisement-row',
  templateUrl: './advertisement-row.component.html',
  styleUrls: ['./advertisement-row.component.scss'],
})
export class AdvertisementRowComponent implements OnInit {

  @Input()
  adUnit: string;

  @Input()
  index: number;

  targeting: any;

  triggerAdLoad$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {
    this.targeting = {
      pos: `mid${this.index-1}`
    };
    this.triggerAdLoad$.next(true);
  }

}
