import { Component, Input } from '@angular/core';
import { Folder } from '../../../constants/interfaces';

@Component({
  selector: 'app-folder-row',
  templateUrl: './folder-row.component.html',
  styleUrls: ['./folder-row.component.scss'],
})
export class FolderRowComponent  {

  @Input() folders: Folder[];

  constructor() {
  }


}
