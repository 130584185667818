import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {Folder, Offer} from '../constants/interfaces';
import {BehaviorSubject} from 'rxjs';
import { OfferKind } from '../constants/enums';
import { ModalController } from '@ionic/angular';
import {Variables} from '../constants/variables';
import {StorageService} from './storage.service';
import {OfferModalComponent} from '../components/offer-modal/offer-modal.component';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  private modal: HTMLIonModalElement;

  private offersRead: number[] = [];

  private _readSubject = new BehaviorSubject([]);

  readonly shareSubject = new BehaviorSubject(null);

  readonly offerHyperlinkSubject = new BehaviorSubject(null);

  constructor(private apiService: ApiService,
              private modalController: ModalController,
              private storageService: StorageService) {
    this.storageService.load(Variables.Storage.OffersRead)
      .subscribe(read => {
        if (read) {
          this.offersRead = read;
          this._readSubject.next(read);
        }
      });
  }

  readOffer(offer: Offer) {
    if (!this.offersRead.includes(offer.offer_id)) {
      this.offersRead.push(offer.offer_id);
      this._readSubject.next(this.offersRead);
      this.storageService.save(Variables.Storage.OffersRead, this.offersRead);
    }
  }

  getTotalOffersRead() {
    return this.offersRead.length;
  }

  async showOfferDetails(offer: Offer, folderSession?: string, folder?: Folder) {
    if (offer.kind === OfferKind.HYPERLINK) {
      this.offerHyperlinkSubject.next(offer);
      window.open(!!offer.deeplink_redirect_url ? offer.deeplink_redirect_url : offer.retailer_redirect_url, '_system');
    } else {
      if (this.modal) {
        this.modal.dismiss();
      }
      this.modal = await this.modalController.create({
        id: 'offer-modal-parentIonContent',
        cssClass: 'modal-offer modal-draggable',
        component: OfferModalComponent,
        componentProps: {offer, folderSession, folder, onShare: () => this.shareSubject.next(offer)}
      });
      this.modal.present();
    }
  }

  async showOfferDetailsById(offerId: number) {
    const offerResponse = await this.apiService.getOffer(offerId).toPromise();
    return this.showOfferDetails(offerResponse);
  }
}
