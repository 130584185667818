import { Component, Input } from '@angular/core';
import { Shortcut } from '../../../constants/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import {InternalRoutingService} from '../../../services/internal-routing.service';

@Component({
  selector: 'app-shortcuts-row',
  templateUrl: './shortcuts-row.component.html',
  styleUrls: ['./shortcuts-row.component.scss'],
})
export class ShortcutsRowComponent {


  @Input() shortcuts: Shortcut[];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private internalService: InternalRoutingService) {

  }

  btnClick(shortcut: Shortcut) {
    this.internalService.handleRouting(shortcut);
  }
}
