import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {FavoriteRetailersService} from './favorite-retailers.service';
import {StorageService} from './storage.service';
import {SQLite, SQLiteObject} from '@ionic-native/sqlite/ngx';
import {Variables} from '../constants/variables';
import {FolderService} from './folder.service';
import {ListService} from './list.service';

@Injectable({
  providedIn: 'root'
})
export class SqliteService {
  
  private readonly key = 'SQLITE_SERVICE_KEY: ';

  private database: SQLiteObject = null;

  constructor(private sqlite: SQLite,
              private apiService: ApiService,
              private storageService: StorageService,
              private folderService: FolderService,
              private listService: ListService,
              private favoriteService: FavoriteRetailersService) {
  }

  async restoreFavoriteRetailers() {
    try {
      const hasSyncedFavoriteRetailers = await this.storageService.load(Variables.Storage.SyncFavoriteRetailers).toPromise();
      if (hasSyncedFavoriteRetailers === null) {
        this.database.executeSql('SELECT * FROM favorite_retailer_ids')
          .then(res => this.saveRetailersToFavorites(res.rows))
          .catch(err => this.saveRetailersToFavorites(err.rows));

        this.storageService.save(Variables.Storage.SyncFavoriteRetailers, true);
      }
    } catch {
      console.log(this.key + 'SQLite: Could not restore favorite retailers');
    }
  }

  async restoreLists() {
    try {
      const hasSyncedLists = await this.storageService.load(Variables.Storage.SyncPersonalLists).toPromise();
      if (hasSyncedLists === null) {
        this.database.executeSql('SELECT * FROM lists')
          .then(res => this.saveLists(res.rows))
          .catch(err => this.saveLists(err.rows));

        this.storageService.save(Variables.Storage.SyncPersonalLists, true);
      }
    } catch {
      console.log(this.key + 'SQLite: Could not restore lists');
    }
  }

  async restoreFoldersRead() {
    try {
      const hasSyncedFoldersRead = await this.storageService.load(Variables.Storage.SyncFoldersRead).toPromise();
      if (hasSyncedFoldersRead === null) {
        this.database.executeSql('SELECT * FROM read_folders')
          .then(res => this.saveFoldersRead(res.rows))
          .catch(err => this.saveFoldersRead(err.rows));

        this.storageService.save(Variables.Storage.SyncFoldersRead, true);
      }
    } catch {
      console.log(this.key + 'SQLite: Could not restore folders read');
    }
  }

  async initializeDatabase() {
    if (this.database !== null) {
      return Promise.resolve();
    }
    try {
      await this.sqlite.selfTest();
      await this.sqlite.echoTest();

      this.database = await this.sqlite.create({
        name: 'reclamefolder_database',
        location: 'default'
      });

      await this.database.open();
    } catch {
      console.log(this.key + 'SQLite: Could not initialize SQLite database');
    }
  }

  /**
   * Restore favorite retailers
   */
  private saveRetailersToFavorites(rows: any) {
    for (let i = 0; i < rows.length; i++) {
      const item = rows.item(i);
      if (item.hasOwnProperty('retailer_id')) {
        this.saveRetailerFromId(parseInt(item.retailer_id, 10));
      }
    }
  }

  private async saveRetailerFromId(retailerId: number) {
    try {
      const retailer = await this.apiService.getRetailer(retailerId).toPromise();
      this.favoriteService.addToFavorites([retailer], false);
    } catch {
      console.log(this.key + `Could not get retailer ${retailerId}`);
    }
  }
  /**
   * End restore favorite retailers
   */

  /**
   * Restore personal lists
   */
  private saveLists(rows: any) {
    for (let i = 0; i < rows.length; i++) {
      const item = rows.item(i);
      this.listService.createList(item.list_name, item._id + '');
    }

    this.database.executeSql('SELECT * FROM offers')
      .then(res => this.saveOffersToList(res.rows))
      .catch(err => this.saveOffersToList(err.rows));

    this.database.executeSql('SELECT * FROM simple_list_items')
      .then(res => this.saveKeywordsToList(res.rows))
      .catch(err => this.saveKeywordsToList(err.rows));
  }

  private saveOffersToList(rows: any) {
    for (let i = 0; i < rows.length; i++) {
      const item = rows.item(i);
      this.saveOfferFromId(item.offer_id, item.list_id + '');
    }
  }

  private saveKeywordsToList(rows: any) {
    for (let i = 0; i < rows.length; i++) {
      const item = rows.item(i);
      this.listService.addKeywordByListId(item.header, item.list_id + '', !!item.crossed_out);
    }
  }

  private async saveOfferFromId(offerId: string, listId: string) {
    try {
      const offerResponse = await this.apiService.getOffer(offerId).toPromise();
      this.listService.addOfferByListId(offerResponse, listId);
    } catch {
      console.log(this.key + `Could not get offer ${offerId}`);
    }
  }
  /**
   * End restore personal lists
   */

  /**
   * Restore folders read
   */
  private saveFoldersRead(rows: any) {
    for (let i = 0; i < rows.length; i++) {
      const item = rows.item(i);
      if (item.hasOwnProperty('folder_version_id')) {
        this.saveFolderReadFromId(item.folder_version_id);
      }
    }
  }

  private async saveFolderReadFromId(id: number) {
    try {
      const folderResponse = await this.apiService.getFolder(id, false).toPromise();
      this.folderService.addReadFolder(folderResponse.folder);
    } catch {
      console.log(this.key + `Could not get folder ${id}`);
    }
  }
  /**
   * End restore folders read
   */


    private saveRetailersNotifications(rows: any) {

    }




}
