import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ScreenSection} from '../../constants/interfaces';
import {TrackingService} from '../../services/tracking.service';


@Component({
  selector: 'app-load-more-button',
  templateUrl: './load-more.component.html',
  styleUrls: ['./load-more.component.scss'],
})
export class LoadMoreComponent {


  @Input() show: boolean = true;

  @Input() btnText: string;

  @Input() section: ScreenSection;

  @Output() btnClick: EventEmitter<void> = new EventEmitter();


  constructor(private trackingService: TrackingService) {
  }

  onClick() {
    this.btnClick.emit();
    this.trackingService.triggerLoadMore(this.section.id, this.section.section_header.title);
  }

}
