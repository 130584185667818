import { Variables } from '../app/constants/variables';

export const environment = {
  production: false,
  staging: false,
  api: {
    baseUrl: 'https://rf-consumer-api-test-dot-reclamefoldernl-eu.appspot.com/v' + Variables.Api.Version + '/',
    authentication: {
      apiKey: 'AIzaSyAKYP8DEtUNc5jckfb_2a1Rd7BcRcAYiQw'
    }
  },
  googleApiKey: 'AIzaSyDGEgOY5OWOSI1NnwyX0tBGrQqRtmq_uCQ',
  appsFlyer: 'WkRh6mbztuGUNPrNGBF5ME',
  // baseAdUnit: '/7191/reclamefolder/reclamefolder.mobile-android',
  baseAdUnit: '/7455/reclamefolder/reclamefolder.mobile-',
  adProxyHost: 'https://rf-ad-proxy.linku.dev'
};
