import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {CanActivateHomeGuard} from './guards/can-activate-home.guard';
import {CanActivateOnboardingGuard} from './guards/can-activate-onboarding.guard';
import { CanDeActivateLocationGuard } from './guards/can-deactivate-location.guard';
import { LocationPage } from './pages/location/location.page';

const routes: Routes = [
  {path: 'home', loadChildren: './pages/home/tabs/tabs.module#TabsPageModule', canActivate: [CanActivateHomeGuard]},
  {
    path: 'on-boarding', loadChildren: './pages/on-boarding/on-boarding.module#OnBoardingPageModule',
    canActivate: [CanActivateOnboardingGuard]
  },
  {path: 'folder', loadChildren: './pages/folder/folder.module#FolderPageModule'},
  {path: 'location', component: LocationPage,
   canDeactivate: [CanDeActivateLocationGuard]
  },
  {path: 'notifications', loadChildren: './pages/notifications/notifications.module#NotificationsPageModule'},
  {path: 'legal', loadChildren: './pages/legal/legal.module#LegalPageModule'},
  {
    path: 'categories/:detail_subject',
    loadChildren: './pages/detail/detail.module#DetailPageModule',
    data: {
      detail_category: 'categories'
    }
  },
  {path: 'coupons', loadChildren: './pages/coupons/coupons.module#CouponsPageModule'},
  {path: 'customer-card', loadChildren: './pages/customer-card/customer-card.module#CustomerCardPageModule'},
  {
    path: 'retailers/:detail_subject', loadChildren: './pages/detail/detail.module#DetailPageModule',
    data: {
      detail_category: 'retailers'
    }
  },
  {
    path: 'opinion',
    loadChildren: './pages/opinion-form/opinion-form.module#OpinionFormPageModule'
  },
  {
    path: 'retailers',
    loadChildren: './pages/retailers-overview/retailers-overview.module#RetailersOverviewPageModule'
  },
  {
    path: 'keywords/:detail_subject',
    loadChildren: './pages/detail/detail.module#DetailPageModule',
    data: {
      detail_category: 'keywords'
    }
  },
  {
    path: 'offers',
    loadChildren: './pages/detail/detail.module#DetailPageModule',
    data: {
      detail_category: 'offers'
    }
  },
  {
    path: 'folders',
    loadChildren: './pages/detail/detail.module#DetailPageModule',
    data: {
      detail_category: 'all_folders'
    }
  },
  {path: '**', redirectTo: '/home/folders', pathMatch: 'full'},
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
