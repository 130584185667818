import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

const STORAGE_KEY = 'RF';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private storage: Storage) { }

  save(key: string, value: any): Observable<void> {
    return from(this.storage.set(key, value));
  }

  load(key: string): Observable<any> {
    return from(this.storage.get(key));
  }

  remove(key: string): Observable<void> {
    return from(this.storage.remove(key));
  }
}
