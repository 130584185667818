import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {TitleCasePipe} from '@angular/common';

@Component({
    selector: 'app-custom-icon',
    templateUrl: './custom-icon.component.html',
    styleUrls: ['./custom-icon.component.scss'],
})
export class CustomIconComponent implements OnInit {

    private _name: string;

    @Input()
    set name(value: string) {
        this._name = value;
        this.updateStyle();
    }

    get getName(): string {
        return this._name;
    }

    @Input()
    color: string;

    @Input()
    size: string;

    styling: any;

    constructor(private sanitize: DomSanitizer,
                private titleCase: TitleCasePipe) {
    }

    ngOnInit(): void {
        this.updateStyle();
    }

    private updateStyle() {
        this.styling = this.sanitize.bypassSecurityTrustStyle('-webkit-mask-image: url(' +
          './assets/icons/' + this.titleCase.transform(this._name) + '.svg' + ');' +
          'mask-image: url(' +
          './assets/icons/' + this.titleCase.transform(this._name) + '.svg' + ');');
    }
}
