import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActionSheetController, AlertController, ToastController} from '@ionic/angular';
import {ActionSheetButton, ActionSheetOptions, AlertButton, AlertInput, AlertOptions} from '@ionic/core';
import {Keyboard, KeyboardResizeMode} from '@ionic-native/keyboard/ngx';

@Injectable({
    providedIn: 'root'
})
export class InteractionService {

    constructor(private translateService: TranslateService,
                private alertCtrl: AlertController,
                private toastCtrl: ToastController,
                private actionSheetCtrl: ActionSheetController,
                private keyboard: Keyboard) {
    }

    async showAlert(options: AlertOptions) {
        options.cssClass = options.cssClass ? options.cssClass : 'alert-blue-buttons';
        options.header = await this.translateService.get(options.header).toPromise();
        if (options.subHeader) {
            options.subHeader = await this.translateService.get(options.subHeader).toPromise();
        }
        if (options.message) {
            options.message = await this.translateService.get(options.message).toPromise();
        }
        if (options.inputs) {
            options.inputs.forEach(async (input: AlertInput) => {
                if (input.placeholder) {
                    input.placeholder = await this.translateService.get(input.placeholder).toPromise();
                }
                if (input.label) {
                    input.label = await this.translateService.get(input.label).toPromise();
                }
            });
        }
        if (options.buttons) {
            options.buttons.forEach(async (button: AlertButton) =>
                button.text = await this.translateService.get(button.text).toPromise());
        }

        const alert = await this.alertCtrl.create(options);
        this.keyboard.setResizeMode(KeyboardResizeMode.Native);
        alert.onWillDismiss()
          .then(() => setTimeout(() => this.keyboard.setResizeMode(KeyboardResizeMode.None), 250));
        alert.present();
    }

    async showActionSheet(options: ActionSheetOptions) {
        options.cssClass = options.cssClass ? options.cssClass : 'sheet-blue-buttons';
        if (options.header) {
            options.header = await this.translateService.get(options.header).toPromise();
        }
        if (options.subHeader) {
            options.subHeader = await this.translateService.get(options.subHeader).toPromise();
        }
        if (options.buttons) {
            options.buttons.forEach(async (button: ActionSheetButton) =>
                button.text = await this.translateService.get(button.text).toPromise());
        }

        const actionSheet = await this.actionSheetCtrl.create(options);
        actionSheet.present();
    }

    async showToast(message: string, parameters?: any, duration: number = 800) {
        this.toastCtrl.getTop().then(currentToast => {
            if (currentToast) {
                currentToast.dismiss();
            }
        });

        const toast = await this.toastCtrl.create({
            message: await this.translateService.get(message, parameters).toPromise(),
            cssClass: 'custom-toast',
            duration
        });
        toast.present();
    }

    dismiss() {
        this.alertCtrl.dismiss();
    }
}
