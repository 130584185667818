import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import { AdTrackingInfo } from '../constants/interfaces';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { environment } from '../../environments/environment';
import { APP_LIFECYCLE } from '../constants/events';

declare var cordova: any;
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class AdvertisementService {

  // https://support.google.com/admanager/answer/6238701
  adTrackingInfo: AdTrackingInfo = {
    idtype: null,
    is_lat: '0',
    rdid: null
  };

  constructor(private platform: Platform,
              private appVersion: AppVersion) {
  }

  async setup(): Promise<void> {
    await this.determineAdTrackingInfo();
    await this.setupXhrListener();
    APP_LIFECYCLE.trigger('ad_init');
    // window.googletag.openConsole();
  }

  private async determineAdTrackingInfo(): Promise<void> {

    if (this.platform.is('cordova')) {
      try {
        const cdvIdfaPluginInfo = await cordova.plugins.idfa.getInfo();

        this.adTrackingInfo.is_lat = cdvIdfaPluginInfo.limitAdTracking ? '1':'0';

        if (this.platform.is('ios')) {
          this.adTrackingInfo.idtype = 'idfa';
          this.adTrackingInfo.rdid = cdvIdfaPluginInfo.idfa;

          if (this.adTrackingInfo.rdid.substr(0,8) === '00000000') {
            // iOS Simulator
            this.adTrackingInfo = this.generateRandomAdTrackingInfo();
          }
        }
        if (this.platform.is('android')) {
          this.adTrackingInfo.idtype = 'adid';
          this.adTrackingInfo.rdid = cdvIdfaPluginInfo.aaid; // Weird that it's aaid, not adid!
        }

      } catch (e) {
        return;
      }

    } else if(! environment.production) {
      // Browser dev
      this.adTrackingInfo = this.generateRandomAdTrackingInfo();
    }

  }

  private generateRandomAdTrackingInfo(): AdTrackingInfo {
    const randomNrBetween10and99 = Math.floor(Math.random() * 90 + 10);
    return {
      is_lat: (Math.round(Math.random()).toString(10) as '0'|'1'),
      rdid: `5A535610-8890-4EBA-B4B6-0E1E408AC0${randomNrBetween10and99}`,
      idtype: 'idfa'
    };
  }


  private async setupXhrListener() {

    const origXHROpen = window.XMLHttpRequest.prototype.open;
    const adTrackingInfo = this.adTrackingInfo;

    let appVersion;
    try {
      appVersion = await this.appVersion.getVersionNumber();
    } catch (e) {
      // console.error(e);
      appVersion = '5.0.8';
    }

    const googleAdsXhrHost = 'https://securepubads.g.doubleclick.net';
    const googleAdsXhrPath = '/gampad/ads';
    const googleAdsXhrUrl = `${googleAdsXhrHost}${googleAdsXhrPath}`;

    const nativeAdParams = {
      ...adTrackingInfo,
      an: `${appVersion}.iphone.nl.aquilasoft.reclamefolder`,
      url: `${appVersion}.iphone.nl.aquilasoft.reclamefolder.adsenseformobileapps.com`,
      hl: 'nl',
      js: 'afma-sdk-i-v7.44.0',
      // caps: 'interactiveVideo_inlineVideo_mraid1_mraid2_mraid3_th_autoplay_mediation_av_sdkAdmobApiForAds_di_' +
      //   'transparentBackground_sdkVideo_sfv_dinm_dim_nav_navc_ct_scroll_dinmo_gls_saiMacro_cpcbg_omidEnabled_gcache_xSeconds',
      // swipeable: 1,
      // m_ast: 'afma',
      // js: 'afma-sdk-i',
      // app_name: 'ionic',
      // region: 'mobile_app',
      // ref: '5.0.8.iphone.nl.aquilasoft.reclamefolder.adsenseformobileapps.com',
      // tc: 'NL',
      // tr: 'nl_NL',
      // locale: 'nl_NL',
      // regionId: 20770,
    };

    const nativeParamsQueryString = Object.keys(nativeAdParams).map(key => key + '=' + nativeAdParams[key]).join('&');

    window.XMLHttpRequest.prototype.open = function() {
      // Arguments = [method, url, params, async, user, password]
      if (arguments[0] === 'GET' &&
          arguments[1].substr(0, googleAdsXhrUrl.length) === googleAdsXhrUrl) {

        const newUrl = `${arguments[1]}&${nativeParamsQueryString}`;

        if (environment.adProxyHost) {
          const proxyUrl = newUrl.replace(googleAdsXhrHost, environment.adProxyHost);
          arguments[1] = proxyUrl;
        } else {
          arguments[1] = newUrl;
        }

      }
      return origXHROpen.apply(this, arguments);
    };
  }

}
