import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {ImageSizeModule} from '../../pipes/image-size/image-size.module';
import {OfferHorizontalComponent} from './offer-horizontal.component';
import {LazyloadModule} from '../../directives/lazyload/lazyload.module';
import {EuroModule} from '../../pipes/euro/euro.module';
import {CustomIconModule} from '../custom-icon/custom-icon.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    ImageSizeModule,
    LazyloadModule,
    EuroModule,
    CustomIconModule
  ],
  declarations: [OfferHorizontalComponent],
  exports: [OfferHorizontalComponent]
})
export class OfferHorizontalModule {
}
