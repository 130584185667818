import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Variables } from '../constants/variables';
import { Location } from '../constants/interfaces';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Geolocation } from '@ionic-native/geolocation/ngx';

declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private _locationSubject = new BehaviorSubject<Location>(null);

  private renderer: Renderer2;

  private geocoder: any;

  private sdkPromise: Promise<void>;

  get location(): Location {
    return this._locationSubject.getValue();
  }

  constructor(private storageService: StorageService,
              private geolocation: Geolocation,
              rendererFactory: RendererFactory2,
              @Inject(DOCUMENT) private _document) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.storageService.load(Variables.Storage.Location)
      .subscribe(location => this._locationSubject.next(location));
  }

  getLocationChange() {
    return this._locationSubject.asObservable();
  }

  async saveLocation(location: Location, mode?: 'gps' | 'manual'): Promise<void> {
    if (mode) {
      location.mode = mode;
    }
    this._locationSubject.next(location);
    await this.storageService.save(Variables.Storage.Location, location).toPromise();
  }

  async initGoogle() {
    if (!this.sdkPromise) {
      this.sdkPromise = new Promise(resolve => {
        (window as any).mapInit = () => resolve();
        const script = this.renderer.createElement('script');
        script.id = 'googleMaps';
        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + environment.googleApiKey + '&callback=mapInit';
        this.renderer.appendChild(this._document.body, script);
      });
    }

    await this.sdkPromise;
    this.geocoder = new google.maps.Geocoder();
  }

  async getLocationFromGps(): Promise<Location> {
    try {
      const gpsLocation = await this.geolocation.getCurrentPosition({timeout: 10000});
      return await this.getLocationFromCoordinates(gpsLocation.coords.latitude, gpsLocation.coords.longitude);
    } catch {
      return Promise.reject(Variables.ErrorMessages.NotAvailable);
    }
  }

  async getLocationFromCoordinates(latitude: number, longitude: number): Promise<Location> {
    await this.initGoogle();
    return new Promise<Location>((resolve, reject) => {
      this.geocoder.geocode({
        location: {
          lat: latitude,
          lng: longitude
        }
      }, (result, status) => {
        if (status === 'OK') {
          let postalCode = null;
          result[0].address_components.forEach(address => {
            if (address.types.includes('postal_code')) {
              postalCode = address.long_name.replace(' ', '');
            }
          });

          const location: Location = {
            latitude,
            longitude,
            postalCode,
            city: result[0].address_components.find(a => a.types.includes('locality')).long_name
          };
          this.saveLocation(location, 'gps');
          resolve(location);
        } else {
          reject(Variables.ErrorMessages.NotAvailable);
        }
      });
    });
  }

  async getLocationFromPostalCode(postalCode: string): Promise<Location> {
    await this.initGoogle();
    return new Promise<Location>((resolve, reject) => {
      this.geocoder.geocode({
        componentRestrictions: {
          country: 'NL',
          postalCode
        }
      }, (result, status) => {
        if (status === 'OK') {
          const location: Location = {
            latitude: result[0].geometry.location.lat(),
            longitude: result[0].geometry.location.lng(),
            postalCode,
            city: result[0].address_components.find(a => a.types.includes('locality')).long_name
          };
          resolve(location);
        } else {
          reject(Variables.ErrorMessages.NotAvailable);
        }
      });
    });
  }
}
