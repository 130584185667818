import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TouchService {

  private firstTouchX = null;
  private firstTouchY = null;
  private amountOfTouches = 0;
  private isSingleTouch: boolean = false;

  onSwipeLeft: EventEmitter<void> = new EventEmitter();
  onSwipeRight: EventEmitter<void> = new EventEmitter();
  onSwipeUp: EventEmitter<void> = new EventEmitter();
  onSwipeDown: EventEmitter<void> = new EventEmitter();

  constructor() { }

  setTargetElement(targetElement: any) {
    targetElement.el.addEventListener('touchstart', (event) => {
      this.amountOfTouches = this.getAmountOfTouches(event); // Update amount of touches on every touch

      if (this.singleTouch(this.amountOfTouches)) {
        this.isSingleTouch = false;
        setTimeout(() => {
        // Check if it's still a single touch after the timeout period
          if (this.singleTouch(this.amountOfTouches)) {
            this.isSingleTouch = true;
            this.handleTouchStart(event);
          }
        }, 25);
      }
    });

    targetElement.el.addEventListener('touchmove', (event) => {
      if (this.isSingleTouch) {
        this.handleTouchMove(event);
      }
    });
  }

  private handleTouchStart(event) {
    const firstTouch = this.getTouches(event)[0];
    this.firstTouchX = firstTouch.clientX;
    this.firstTouchY = firstTouch.clientY;
  }

  private getTouches(event) {
    return event.touches;
  }

  private getAmountOfTouches(event) {
    return event.touches.length;
  }

  private singleTouch(amountOfTouches) {
    return amountOfTouches === 1;
  }

  private handleTouchMove(event) {
    if ( !this.firstTouchX || !this.firstTouchY ) {
      return;
    }

    const touchMoveUpX = event.touches[0].clientX;
    const touchMoveUpY = event.touches[0].clientY;

    const movedDistanceX = this.firstTouchX - touchMoveUpX;
    const movedDistanceY = this.firstTouchY - touchMoveUpY;

    if ( Math.abs( movedDistanceX ) > Math.abs( movedDistanceY ) ) {/*most significant*/
      if ( movedDistanceX > 0 ) {
        this.onSwipeLeft.emit();
      } else {
        this.onSwipeRight.emit();
      }
    } else {
      if ( movedDistanceY > 0 ) {
        this.onSwipeUp.emit();
      } else {
        this.onSwipeDown.emit();
      }
    }

    /* reset values */
    this.firstTouchX = null;
    this.firstTouchY = null;
  }
}
