import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ImageSizeModule} from '../../pipes/image-size/image-size.module';
import { KeywordHighlightedComponent } from './keyword-highlighted.component';
import { LazyloadModule } from '../../directives/lazyload/lazyload.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        ImageSizeModule,
        LazyloadModule,
        TranslateModule.forChild()
    ],
    declarations: [KeywordHighlightedComponent],
    exports: [KeywordHighlightedComponent]
})
export class KeywordHighlightedModule {
}
