import {Component, Injector} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from './services/storage.service';
import {Variables} from './constants/variables';
import {Router} from '@angular/router';
import {InternalRoutingService} from './services/internal-routing.service';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {AppProfileService} from './services/app-profile.service';
import {TrackingService} from './services/tracking.service';
import {SplashScreenService} from './services/splash-screen.service';
import {NotificationsService} from './services/notifications.service';
import {InteractionService} from './services/interaction.service';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {OfferService} from './services/offer.service';
import {ApiService} from './services/api.service';
import {Device} from '@ionic-native/device/ngx';
import {SqliteService} from './services/sqlite.service';
import {RealmService} from './services/realm.service';
import {PreferencesService} from './services/preferences.service';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {AdvertisementService} from './services/advertisement.service';
import { UserAgent } from '@ionic-native/user-agent/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { APP_LIFECYCLE } from './constants/events';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  static INJECTOR: Injector;

  swipeGestureEnabled: boolean;

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private storageService: StorageService,
    private router: Router,
    private internalRouting: InternalRoutingService,
    private appProfileService: AppProfileService,
    private offerService: OfferService,
    private trackingService: TrackingService,
    private splashScreenService: SplashScreenService,
    private statusBar: StatusBar,
    private device: Device,
    private nativeSplashScreen: SplashScreen,
    private interactionService: InteractionService,
    private notificationsService: NotificationsService,
    private deeplink: Deeplinks,
    private sqliteService: SqliteService,
    private realmService: RealmService,
    private preferencesService: PreferencesService,
    private screenOrientation: ScreenOrientation,
    private advertisementService: AdvertisementService,
    private userAgent: UserAgent,
    private appVersion: AppVersion,
    injector: Injector
  ) {
    AppComponent.INJECTOR = injector;
    this.initTranslations();
    this.initApp();
  }

  async initApp() {
    this.platform.ready()
      .then(async () => {

        await this.setRfUserAgent();

        this.advertisementService.setup();


        const shouldShowSplashScreen = await this.splashScreenService.shouldShowSplashScreen();

        const onboardingShownPreviousNativeApp = await this.preferencesService
          .getPreference(Variables.NativeApp.UserPreferences.OnboardingShown);
        if (onboardingShownPreviousNativeApp && !shouldShowSplashScreen) {
          this.storageService.save(Variables.Storage.ShowSplashScreen, 'always');
        }
        if (shouldShowSplashScreen || onboardingShownPreviousNativeApp) {
          this.trackingService.triggerConfigRefreshed();
          await this.splashScreenService.show();
          this.nativeSplashScreen.hide();
        }

        this.swipeGestureEnabled = this.platform.is('ios');
        if (this.platform.is('cordova')) {
          await this.nativeSplashScreen.hide();
          await this.statusBar.hide();

          this.initRotationLock();

          if (this.device.version.startsWith('5')) {
            this.statusBar.backgroundColorByHexString('#E8E8E8');
          }
          if (this.platform.is('android')) {
            this.initSqlRestoration();
            this.initAndroidPreferencesRestore();
          } else if (this.platform.is('ios')) {
            this.initRealmRestoration();
            this.initIOSPreferencesRestore();
          }
        }

        this.initNotifications();
        this.initBackBtn();
        await this.initTracking();
        this.initDeeplinks();

        APP_LIFECYCLE.trigger('app_init');


      });
  }
  private async setRfUserAgent() {

      if (this.platform.is('cordova')) {
        const currentUserAgent = await this.userAgent.get();
        const rfApiUserAgent = await this.determineRfUserAgent();

        const combinedUserAgent = `${rfApiUserAgent} ${currentUserAgent}`;
        await this.userAgent.set(combinedUserAgent);
      }

      APP_LIFECYCLE.trigger('ua_init');

    }

    private async determineRfUserAgent() {
      const deviceType = this.platform.is('tablet') ? 'Tablet' : 'Phone',
        appPlatform = this.platform.is('android') ? 'Android' : 'iOS',
        appVersion = await this.appVersion.getVersionNumber();

      return `RF-API/${Variables.Api.Version} ${deviceType} ${appPlatform}/${this.device.version} ` +
        `${this.device.manufacturer}/${this.device.model} RF-${appPlatform}-App/${appVersion}`;

    }

  private initTranslations() {
    this.translate.addLangs(['nl']);
    this.translate.setDefaultLang('nl');
    this.translate.use('nl');
  }

  private initBackBtn() {
    this.platform.backButton
      .subscribe(async () => {
        if (this.router.url === Variables.Pages.HomeFolders) {
          // exit app
          (navigator as any).app.exitApp();
        } else {
          const activeModal = await this.modalCtrl.getTop();
          if ([Variables.Pages.HomeSearch, Variables.Pages.HomeLists,
              Variables.Pages.HomeSettings, Variables.Pages.HomeCards].includes(this.router.url) &&
            !activeModal) {
            // Navigate to home
            this.router.navigateByUrl(Variables.Pages.HomeFolders);
          }
        }
      });
  }

  private async initTracking() {
    try {
      const cookie = await this.storageService.load(Variables.Storage.Cookie).toPromise();
      if (cookie !== null) {
        await this.trackingService.enableFirebase(true);
        await this.trackingService.triggerSessionStart();
      }
    } catch {
      console.log('Tracking not available');
    }
  }

  private initNotifications() {
    this.notificationsService.setBadge(0);
    this.notificationsService.onNotification()
      .subscribe(notificationData => {
        if (!!notificationData.tap) {
          setTimeout(() => {
            this.internalRouting.handleNotificationDeeplink(notificationData.deeplink);
          }, 1000);
        } else {
          const notification = this.platform.is('ios') ? {
            header: notificationData.notification.title,
            subHeader: notificationData.notification.body
          } : {
            header: notificationData.title,
            subHeader: notificationData.body
          };
          this.interactionService.showAlert({
            header: notification.header,
            subHeader: notification.subHeader,
            buttons: [{
              text: 'global.cancel'
            }, {
              text: 'global.open',
              handler: () => {
                this.internalRouting.handleNotificationDeeplink(notificationData.deeplink);
              }
            }]
          });
        }
      });
  }

  private initDeeplinks() {
    const matches = {
      Folder: 'folder',
      NewFolders: 'new',
      Offers: 'offers',
      Wildcard: 'wildcard'
    };

    this.deeplink.route({
      '/folders/:folderId': matches.Folder,
      '/nieuwe-folders': matches.NewFolders,
      '/aanbiedingen': matches.Offers,
      '/:wildcard': matches.Wildcard
    })
      .subscribe(match => {
        if (match.$route === matches.Wildcard && !match.$args.wildcard.length) {
          match.$route = null;
        }

        switch (match.$route) {
          case matches.Folder :
            this.internalRouting.handleVariableDeeplink({
              folder: match.$args.folderId
            });
            break;
          case matches.NewFolders :
          case matches.Offers :
          case matches.Wildcard :
            this.internalRouting.navigateToRoute(match.$link.path);
            break;
          default :
            this.internalRouting.checkDeeplinkMatch(match.$link.queryString, match.$args);
            break;
        }
      }, () => {
        console.log('Deeplinks not available in browser');
      });
  }

  private async initSqlRestoration() {
    await this.sqliteService.initializeDatabase();
    this.sqliteService.restoreFavoriteRetailers();
    this.sqliteService.restoreLists();
    this.sqliteService.restoreFoldersRead();
  }

  private async initRealmRestoration() {
    await this.realmService.initializeDatabase();
    this.realmService.restoreFavoriteRetailers();
    this.realmService.restoreFoldersRead();
    this.realmService.restoreCustomerCards();
    this.realmService.restoreLists();
    this.realmService.restoreSearchQueries();
    this.realmService.restoreSubscribedRetailers();
    this.realmService.restoreUserLocation();
  }

  private async initAndroidPreferencesRestore() {

  }

  private async initIOSPreferencesRestore() {
    try {
      const iosPreferencesRestored = await this.storageService.load(Variables.NativeApp.UserPreferences.Restored).toPromise();
      if (iosPreferencesRestored === null) {
        const token = await this.preferencesService.getPreference(Variables.NativeApp.UserPreferences.FcmToken);
        if (token) {
          this.appProfileService.toggleNotifications(true);
          const themesEnabled = await this.preferencesService.getPreference(Variables.NativeApp.UserPreferences.ThemeNotificationsEnabled);
          if (themesEnabled === true) {
            this.storageService.save(Variables.Storage.ThemeNotifications, true);
            this.trackingService.setUserPropertyThemeNotifications(true);
          }
        }
      }
      this.storageService.save(Variables.NativeApp.UserPreferences.Restored, true);
    } catch {
      console.log('Could not restore iOS preferences');
    }
  }

  private initRotationLock() {
    if (!this.platform.is('tablet')) {
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    }
  }
}
