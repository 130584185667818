import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ApiService} from '../../services/api.service';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '../../services/storage.service';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {ConfigService} from '../../services/config.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {

  onDismiss: () => void;

  lastUpdate: string;

  constructor(private modalCtrl: ModalController,
              private api: ApiService,
              private configService: ConfigService,
              private storageService: StorageService,
              private statusBar: StatusBar,
              private translateService: TranslateService) {
  }

  progressValue = 0;

  errorStatusCode: number = null;

  private static getRandomProgress() {
    return Math.floor(Math.random() * 5) / 10;
  }

  private static getRandomTimeout(max: number, min: number = 0) {
    return Math.floor(Math.random() * max) + min;
  }

  ngOnInit() {
    this.statusBar.hide();
    this.handleConfigAndSplashScreen();
  }

  private async getConfigSettings() {
    try {
      const updatedAt = moment(await this.configService.getValue('content_updated_at')),
        // I use the `response_time` so i get a server time instead of JS `new Date` which can be different
        currentTime = moment(await this.configService.getValue('response_time')),
        duration = moment.duration(currentTime.diff(updatedAt));
      if (Math.floor(duration.asMinutes()) > 59) {
        this.lastUpdate = await this.translateService.get('splash.updateHours', {
          time: Math.floor(duration.asHours())
        }).toPromise();
      } else if (Math.floor(duration.asMinutes()) <= 1) {
        this.lastUpdate = await this.translateService.get('splash.updateRecent').toPromise();
      } else {
        this.lastUpdate = await this.translateService.get('splash.updateMinutes', {
          time: Math.floor(duration.asMinutes())
        }).toPromise();
      }
      this.errorStatusCode = null;
    } catch (e) {
      this.errorStatusCode = 0;
    }
  }

  adReady() {
    console.log('SplashScreen advertisement rendered');
  }

  async handleConfigAndSplashScreen() {
    await this.getConfigSettings();
    if (this.errorStatusCode === null) {
      setTimeout(() => {
        const randomTimeout1 = SplashScreenComponent.getRandomTimeout(1000, 200),
          randomTimeout2 = SplashScreenComponent.getRandomTimeout(2000, 1000);
        setTimeout(() => this.progressValue += SplashScreenComponent.getRandomProgress(), randomTimeout1);
        setTimeout(() => this.progressValue += SplashScreenComponent.getRandomProgress(), randomTimeout2);
        setTimeout(() => {
          this.progressValue = 1;
          setTimeout(() => {
            this.statusBar.show();
            this.modalCtrl.dismiss(null, null, 'splash-screen')
              .then(() => {
                this.onDismiss();
              });
          }, 200);
        }, 3000);
      }, 1000);
    }
  }
}
