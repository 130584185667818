import { Injectable } from '@angular/core';
import { Retailer } from '../constants/interfaces';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';
import { Variables } from '../constants/variables';
import { InteractionService } from './interaction.service';
import { ApiService } from './api.service';
import {TrackingService} from './tracking.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteRetailersService {

  private favorites: BehaviorSubject<Retailer[]> = new BehaviorSubject([]);

  get getFavorites() {
    return this.favorites.asObservable();
  }

  get asIdArray(): number[] {
    const favorites = this.favorites.getValue();
    return favorites.map(f => f.retailer_id);
  }

  constructor(private storageService: StorageService,
              private interactionService: InteractionService,
              private trackingService: TrackingService,
              private api: ApiService) {
    this.initializeFavorites();
  }

  private initializeFavorites() {
    this.storageService.load(Variables.Storage.FavoriteRetailers).subscribe(currentValue => {
      if (currentValue && currentValue.length > 0) {
        this.favorites.next(currentValue);
      }
    });
  }

  async addToFavorites(retailers: Retailer[], showToast: boolean = true) {
    if (retailers && retailers.length > 0) {
      const currentValue = this.favorites.getValue();
      let addedRetailer: Retailer;
      retailers.forEach(r => {
        if (currentValue.findIndex(c => c.retailer_id === r.retailer_id) === -1) {
          currentValue.push(r);
          addedRetailer = r;
          this.api.addToFavorites(r.retailer_id);
          this.trackingService.triggerRetailerFavoriteToggle(r.retailer_permaname, true);
        }
      });
      currentValue.sort((a, b) => (a.retailer_name.toLowerCase() > b.retailer_name.toLowerCase()) ? 1 :
        ((b.retailer_name.toLowerCase() > a.retailer_name.toLowerCase()) ? -1 : 0));
      await this.saveInStorage(currentValue);
      this.favorites.next(currentValue);
      if (showToast && addedRetailer) {
        this.interactionService.showToast('favorites.addedToast', {retailer: addedRetailer.retailer_name});
      }
    }
  }

  async removeFromFavorites(retailer: Retailer, showToast: boolean = true) {
    const currentValue = this.favorites.getValue();
    const index = currentValue.findIndex(r => r.retailer_id === retailer.retailer_id);
    const removedRetailer = currentValue[index];
    if (index !== -1) {
      currentValue.splice(index, 1);
      this.api.removeFromFavorites(removedRetailer.retailer_id);
    }
    await this.saveInStorage(currentValue);
    this.favorites.next(currentValue);
    if (showToast) {
      this.interactionService.showToast('favorites.removedToast', {retailer: removedRetailer.retailer_name});
    }
    this.trackingService.triggerRetailerFavoriteToggle(retailer.retailer_permaname, false);
  }

  clearFavorites(retailers: Retailer[]) {
    retailers.forEach(retailer => {
      this.api.removeFromFavorites(retailer.retailer_id);
      this.trackingService.triggerRetailerFavoriteToggle(retailer.retailer_permaname, false);
    });
    this.saveInStorage([]);
    this.favorites.next([]);
  }

  isFavorite(retailerId: number) {
    return this.asIdArray.includes(retailerId);
  }

  toggle(retailer: Retailer) {
    if (this.isFavorite(retailer.retailer_id)) {
      this.removeFromFavorites(retailer);
    } else {
      this.addToFavorites([retailer]);
    }
  }

  private saveInStorage(value: Retailer[]) {
    return this.storageService.save(Variables.Storage.FavoriteRetailers, value).toPromise();
  }
}
