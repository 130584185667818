export const Variables = {
  Api: {
    Version: 6, // TODO Load this value in Android for setting the user agent
    Screens: 'screens',
    Home: 'home',
    Categories: 'categories',
    Retailers: 'retailers',
    Folders: 'folders',
    FolderEditions: 'folders/editions',
    Search: 'search',
    OffersScreens: 'screens/offers',
    CategoryScreens: 'screens/categories',
    RetailerScreens: 'screens/retailers',
    KeywordScreens: 'screens/keywords',
    Details: 'details',
    Config: 'config',
    Contact: 'contact',
    Suggestions: 'suggestions',
    Stores: 'stores',
    Offer: 'offers',
    Keywords: 'keywords',
    LocalOffers: 'local_offers',
    AllFolders: 'all_folders',
    FavoriteRetailers: 'profiles/favorite-retailers',
    Coupons: 'coupons',
    Favorites: 'favorites'
  },
  ScreenSections: {
    ForMobile: 'PHONE',
    FolderListing: 'folder_listing',
    OfferListing: 'offer_listing',
    LocalListing: 'local_offers',
    CategoryListing: 'category_listing',
    RetailerListing: 'retailer_listing',
    Teaser: 'teaser_listing',
    Shortcuts: 'shortcuts',
    Keyword: 'keyword_listing',
    OfferDetail: 'offer_detail',
    StoreListing: 'store_listing',
    HeroHeader: 'hero_header',
    CustomText: 'custom_text',
    RetailerAnchorListing: 'retailer_anchor_listing'
  },
  ScreenSectionItemTypes: {
    Heading: 'heading',
    Teaser: 'teaser',
    RetailerRow: 'retailer_row',
    RetailerCarousel: 'retailer_carousel',

    ShortCutsRow: 'shortcuts_row',
    OfferCarousel: 'offer_carousel',
    FolderCarousel: 'folder_carousel',
    StoreCarousel: 'store_carousel',

    FolderRow: 'folder_row',
    LocalOffers: 'local_offers',
    OfferRow: 'offer_row',
    KeywordsRow: 'keywords_row',
    KeywordsCarousel: 'keywords_carousel',

    LoadMoreButton: 'load_more',
    LoadAllButton: 'load_all',

    KeywordSection: 'keyword_section',
    Shortcuts: 'shortcuts',
    FolderListing: 'folder_listing',
    Offer: 'offer_listing',
    LocalListing: 'local_offers',
    CategoryListing: 'category_listing',
    RetailerListing: 'retailer_listing',
    Keyword: 'keyword_listing',
    OfferDetail: 'offer_detail',
    StoreListing: 'store_listing',
    HeroHeader: 'hero_header',
    CustomText: 'custom_text',
    Coupon: 'coupon_section',
    Advertisement: 'advertisement'
  },
  Storage: {
    Location: 'location',
    FavoriteRetailers: 'favorite_retailers',
    FirstRun: 'first_run',
    FolderIDsRead: 'folder_ids_read',
    ShowSplashScreen: 'show_splash_screen',
    TriggerSaveLocation: 'trigger_location_save',
    Cookie: 'cookie',
    Profile: 'profile',
    OffersRead: 'offers_read',
    SessionCount: 'sessions_count',
    Sessions: 'sessions',
    SyncFavoriteRetailers: 'synced_favorite_retailers_from_sqlite',
    SyncPersonalLists: 'synced_personal_lists_from_sqlite',
    SyncFoldersRead: 'synced_folders_read_from_sqlite',
    CustomerCards: 'customer_cards',
    RetailersNotifications: 'retailers_notifications',
    SyncCustomerCards: 'synced_customer_cards',
    SyncSearchQueries: 'synced_search_queries',
    SyncSubscribedRetailers: 'synced_subscribed_users',
    SyncUserLocation: 'synced_user_location',
    ThemeNotifications: 'theme_notification_permissions',
    AskedLocationBefore: 'asked_location_before',
    LastSyncedOffers: 'last_synced_offers',
    Lists: 'lists'
  },
  Pages: {
    HomeFolders: '/home/folders',
    HomeSearch: '/home/search',
    HomeSettings: '/home/settings',
    HomeLists: '/home/lists',
    HomeCards: '/home/cards',
    Favorites: '/favorites',
    Tabs: {
      Folders: 'folders',
      Favorites: 'favorites',
      Lists: 'lists',
      Settings: 'settings',
      Cards: 'cards',
      Children: {
        Categories: 'categories',
        Retailers: 'retailers',
        Keywords: 'keywords',
        Opinion: 'opinion',
        Offers: 'offers',
        Folders: 'folders'
      }
    },
    Folder: 'folder',
    Location: 'location',
    Notifications: 'notifications',
    Legal: 'legal',
    Coupons: 'coupons',
    CustomerCards: 'customer-card'
  },
  Filters: {
    Folders: 'folders',
    Offers: 'aanbiedingen',
    Retailers: 'winkels'
  },
  ErrorMessages: {
    ListNameExists: 'list_name_exists',
    NotAvailable: 'not_available',
    PostalCodeUnkown: 'postal_code_unknown'
  },
  LocalOfferViews: {
    Empty: 'empty',
    NoLocation: 'no_location',
    NoResults: 'no_results',
    Valid: 'valid'
  },
  NativeApp: {
    UserPreferences: {
      Restored: 'rf-preferences-restored',
      OnboardingShown: 'rf.onboarding.onboarding-shown',
      CookieStatementVersion: 'rf.cookie.cookie-statement-version',
      FcmToken: 'rf.profile-manager.fcm-token',
      ThemeNotificationsEnabled: 'rf.user-property.theme-notifications-enabled'
    }
  },
  Locations: {
    Netherlands: {
      latitude: 52.1627856,
      longitude: 5.4835762
    }
  }
};
