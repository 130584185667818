import { Component, Input, OnInit } from '@angular/core';
import { Folder } from '../../constants/interfaces';
import { PlaceholderService } from '../../services/placeholder.service';
import { TextContrastService } from '../../services/text-contrast.service';
import { Router } from '@angular/router';
import { FolderService } from '../../services/folder.service';
import { Variables } from '../../constants/variables';
import { FolderLayout } from '../../constants/enums';
import { InteractionService } from '../../services/interaction.service';

@Component({
    selector: 'app-folder-highlighted',
    templateUrl: './folder-highlighted.component.html',
    styleUrls: ['./folder-highlighted.component.scss'],
})
export class FolderHighlightedComponent implements OnInit {

    @Input() readonly folder: Folder;

    lightTheme: boolean;
    read: boolean;
    isPremium: boolean;

    readonly pages = Variables.Pages;

    constructor(public placeholder: PlaceholderService,
                private textContrast: TextContrastService,
                public router: Router,
                private folderService: FolderService,
                private interactionService: InteractionService) {
    }

    ngOnInit() {
        this.lightTheme = this.textContrast.shouldBeLightText(this.folder.retailer_color);
        this.folderService.getFoldersRead()
          .subscribe(read => {
              this.read = read.findIndex(r => r.folder_version_id === this.folder.folder_version_id) >= 0;
          });
        this.isPremium = this.folderService.isPremium(this.folder);
    }

    handleFolderNavigation() {
        if (this.folder.preferred_layouts && this.folder.preferred_layouts[0] === FolderLayout.FOLDER_LOC_REQ) {
            this.interactionService.showAlert({
                header: 'folder.setLocation',
                subHeader: 'folder.needsLocation',
                buttons: [
                    {
                        text: 'global.cancel',
                        handler: () => {
                            this.interactionService.dismiss();
                        }
                    },
                    {
                        text: 'global.ok',
                        handler: () => {
                            this.router.navigate([this.pages.Location], { state: { open_folder_edition_id: this.folder.folder_edition_id } });
                        }
                    }]
            });
        } else {
            this.router.navigate([this.pages.Folder, this.folder.folder_version_id]);
        }
    }
}
