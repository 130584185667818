import { Component, Input } from '@angular/core';
import { Folder, SectionItem } from '../../../constants/interfaces';

@Component({
  selector: 'app-folder-carousel',
  templateUrl: './folder-carousel.component.html',
  styleUrls: ['./folder-carousel.component.scss'],
})
export class FolderCarouselComponent {

  @Input() folders: Folder[];

  @Input() section: SectionItem;

  @Input() showTotalOverview: boolean;

  constructor() {
  }

}
