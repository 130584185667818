import {NgModule} from '@angular/core';
import {EuroPipe} from './euro.pipe';


@NgModule({
    declarations: [EuroPipe],
    exports: [EuroPipe]
})
export class EuroModule {
}
