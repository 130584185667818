import {Component, Input} from '@angular/core';
import {Teaser} from '../../../constants/interfaces';

@Component({
  selector: 'app-teaser-row',
  templateUrl: './teaser-row.component.html',
  styleUrls: ['./teaser-row.component.scss'],
})
export class TeaserRowComponent {

  @Input()
  teasers: Teaser[];

}
