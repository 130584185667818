export class Helpers {

  public static range(from: number, to: number): number[] {
    const range: number[] = [];
    for(let i = from; i < to; i++) {
      range.push(i);
    }
    return range;
  }

  public static arrayChunk(inputArr: any[], chunkSize: number): any[] {
    const chunkedArr = [];
    let i,j;
    for (i=0, j=inputArr.length; i<j; i+=chunkSize) {
      chunkedArr.push(inputArr.slice(i,i+chunkSize));
    }
    return chunkedArr;
  }

}
