import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'imageSize'
})
export class ImageSizePipe implements PipeTransform {

    transform(imageUrl: string, size: number): string {
        const path = imageUrl.includes('?') ? imageUrl.split('?')[0] : imageUrl;
        const params: any = imageUrl.includes('?') ?
            this.parseQueryString(imageUrl.substring(imageUrl.indexOf('?') + 1)) :
            {};
        params.size = size;
        return path + '?' + Object.keys(params).reduce((a, k) => {
            a.push(k + '=' + encodeURIComponent(params[k]));
            return a;
        }, []).join('&');
    }

    private parseQueryString(queryString) {
        const params = {};
        let queries, temp, i, l;
        // Split into key/value pairs
        queries = queryString.split('&');
        // Convert the array of strings into an object
        for (i = 0, l = queries.length; i < l; i++) {
            temp = queries[i].split('=');
            params[temp[0]] = temp[1];
        }
        return params;
    }

}
