import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {RestangularModule} from 'ngx-restangular';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {registerLocaleData, TitleCasePipe} from '@angular/common';
import localeDutch from '@angular/common/locales/nl';
import {SearchComponent} from './components/search/search.component';
import {FormsModule} from '@angular/forms';
import {CustomIconModule} from './components/custom-icon/custom-icon.module';
import {ImageSizeModule} from './pipes/image-size/image-size.module';
import {EuroModule} from './pipes/euro/euro.module';
import {LazyloadModule} from './directives/lazyload/lazyload.module';
import {SplashScreenComponent} from './components/splash-screen/splash-screen.component';
import {OfferHorizontalModule} from './components/offer-horizontal/offer-horizontal.module';
import {CookieWallComponent} from './components/cookie-wall/cookie-wall.component';
import {AdvertisementModule} from './components/advertisement/advertisement.module';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {IonicStorageModule} from '@ionic/storage';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {Device} from '@ionic-native/device/ngx';
import {Appsflyer} from '@ionic-native/appsflyer/ngx';
import {Network} from '@ionic-native/network/ngx';
import {ErrorMessageModule} from './components/error-message/error-message.module';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {HTTP} from '@ionic-native/http/ngx';
import {SQLite} from '@ionic-native/sqlite/ngx';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {UserAgent} from '@ionic-native/user-agent/ngx';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {IonicGestureConfig} from './helpers/ionic-gesture-config';
import {OpenNativeSettings} from '@ionic-native/open-native-settings/ngx';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {OfferModalModule} from './components/offer-modal/offer-modal.module';
import { LocationPageModule } from './pages/location/location.module';
import {IonicErrorHandler} from './helpers/ionicErrorHandler';
import { DfpModule } from 'ngx-dfp';
import { DfpConfig } from 'ngx-dfp/class';

registerLocaleData(localeDutch, 'nl');

// Function for setting the default restangular configuration
export function RestangularConfigFactory(restAngularProvider) {
  restAngularProvider.setBaseUrl(environment.api.baseUrl);
  restAngularProvider.addFullRequestInterceptor((element, operation, path, url: string, headers, params) => {
    if (!headers.hasOwnProperty('X-API-key') && !url.includes('rest.reclamefolder.nl')) {
      headers['X-API-key'] = environment.api.authentication.apiKey;
    }

    if (!url.includes('rest.reclamefolder.nl')) {
      headers['content-type'] = 'application/json';
    }

    return {
      params,
      headers,
      element
    };
  });
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


const DFP_CONFIG: DfpConfig = {
  idleLoad: false,
  enableVideoAds: true,
  personalizedAds: true,
  singleRequestMode: false,
  onSameNavigation: 'ignore',
  loadGPT: false,
  // location: [51.844512,5.848372],
  // location: "2351,NL",
  forceSafeFrame: true,
  safeFrameConfig: {
    allowOverlayExpansion: false,
    allowPushExpansion: false,
    sandbox: true
  },
};

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    SplashScreenComponent,
    CookieWallComponent
  ],
  entryComponents: [
    SearchComponent,
    SplashScreenComponent,
    CookieWallComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      backButtonText: ''
    }),
    IonicStorageModule.forRoot({
      driverOrder: ['localstorage']
    }),
    FormsModule,
    AppRoutingModule,
    RestangularModule.forRoot(RestangularConfigFactory),
    HttpClientModule,
    DfpModule.forRoot(DFP_CONFIG),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    OfferModalModule,
    OfferHorizontalModule,
    CustomIconModule,
    AdvertisementModule,
    ImageSizeModule,
    EuroModule,
    LazyloadModule,
    ErrorMessageModule,
    LocationPageModule
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    { provide: ErrorHandler, useClass: IonicErrorHandler },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: IonicGestureConfig
    },
    TitleCasePipe,
    StatusBar,
    SplashScreen,
    Geolocation,
    SocialSharing,
    Device,
    Appsflyer,
    Network,
    AppVersion,
    Deeplinks,
    Keyboard,
    InAppBrowser,
    HTTP,
    SQLite,
    BarcodeScanner,
    UserAgent,
    FirebaseX,
    OpenNativeSettings,
    ScreenOrientation
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}


