import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Action, BackgroundRequest, DeeplinkRouting} from '../constants/enums';
import {Button, Keyword, Shortcut, Store, Teaser, Notification, AnyKey} from '../constants/interfaces';
import {InteractionService} from './interaction.service';
import {FavoriteRetailersService} from './favorite-retailers.service';
import {ListService} from './list.service';
import {ModalController, Platform} from '@ionic/angular';
import {Variables} from '../constants/variables';
import {OfferService} from './offer.service';
import {TrackingService} from './tracking.service';
import {ApiService} from './api.service';
import {SplashScreenService} from './splash-screen.service';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class InternalRoutingService {

  constructor(private router: Router,
              private interactionService: InteractionService,
              private listService: ListService,
              private modalCtrl: ModalController,
              private api: ApiService,
              private splashScreenService: SplashScreenService,
              private platform: Platform,
              private offerService: OfferService,
              private inAppBrowser: InAppBrowser,
              private trackingService: TrackingService,
              private favoriteRetailersService: FavoriteRetailersService) {
  }

  readonly androidScheme = 'reclamefolder://?';
  private readonly websiteUrl = 'https://www.reclamefolder.nl';

  private readonly internalDeeplinkRegex = [
    '/winkels-([^~]*).html',
    '/([^~]*)/folders',
    '/([^~]*)/aanbiedingen',
    '/([^~]*)/winkels',
    '/aanbiedingen-([^~]*).html',
    '/aanbiedingen/([^~]*)/([^~]*)',
    '/aanbieding/([^~]*)',
    '/aanbieding/([^~]*)/acties',
    '/aanbiedingen',
    '/aanbiedingen-in-de-buurt',
    '/nieuwe-folders',
    '/favorieten'
  ];

  private static getMatchingRoute(pageIndex: number, params: string[]) {
    // TODO: FIX ROUTES TO LOGICAL VALUES
    // TODO: fix with variables
    // The ones in comment are not used in the app
    switch (pageIndex) {
      case DeeplinkRouting.CategoryDetail :
        return `/categories/${params[0]}`;
      case DeeplinkRouting.CategoryFolders :
        return `/categories/${params[0]}`; // folders
      case DeeplinkRouting.CategoryOffers :
        return `/categories/${params[0]}`; // offers
      case DeeplinkRouting.CategoryRetailers :
        return `/categories/${params[0]}`; // stores
      case DeeplinkRouting.RetailerDetails :
        return `/retailers/${params[0]}`;
      case DeeplinkRouting.OfferDetails :
        return params[1];
      case DeeplinkRouting.KeywordDetails :
        return `/keywords/${params[0]}`;
      case DeeplinkRouting.KeywordOffers :
        return `/keywords/${params[0]}`;
      case DeeplinkRouting.Offers :
        return '/offers';
      case DeeplinkRouting.LocalOffers :
        return '/offers';
      case DeeplinkRouting.AllFolders:
        return '/folders';
      case DeeplinkRouting.Favorites:
        return '/home/favorites';
      default :
        return '';
    }
  }

  private handleAction(action: string, parameters: { [key: string]: any }) {
    switch (action) {
      case BackgroundRequest.SAVE_LOCAL_LIST:
        return this.listService.addOfferToList(parameters.offer, parameters.folderSession, parameters.folder);
      case BackgroundRequest.ADD_REMOVE_FAV_TOGGLE:
        return this.favoriteRetailersService.toggle(parameters.retailer);
      default :
        return this.interactionService.showAlert({
          header: 'global.oops',
          subHeader: 'global.unhandledAction',
          buttons: [{
            text: 'global.ok'
          }]
        });
    }
  }

  private async openFolderViewer(options: { [key: string]: any }) {
    await this.modalCtrl.dismiss();
    return this.router.navigate(['/', Variables.Pages.Folder, options.folder_version_id, {
      p: options.page_number
    }]);
  }

  handleRouting(request: Teaser | Keyword | Store | Shortcut | Button | Notification, options: { [key: string]: any } = {}) {
    options = Object.assign(request.parameters ? request.parameters : {}, options);
    if (request.action === Action.INTERNAL_DEEPLINK) {
      return this.navigateToRoute(request.internal_deeplink);
    } else if (request.action === Action.DEEPLINK) {
      if (request.deeplink.startsWith(this.androidScheme)) {
        return this.platform.is('ios') ? this.handleNotificationDeeplink(request.deeplink) : window.location.href = request.deeplink;
      } else if (request.deeplink.includes('view-folder-version')) {
        this.closeAllModals();
        return this.openFolderViewer(options);
      } else if (request.deeplink.startsWith(this.websiteUrl)) {
        return this.navigateToRoute(request.deeplink.replace(this.websiteUrl, ''));
      } else {
        this.trackingService.triggerLinkOpen(options.offer, options.folderSession, options.folder);
        return this.inAppBrowser.create(request.deeplink, '_system');
      }
    } else if (request.action === Action.BACKGROUND_REQUEST) {
      return this.handleAction(request.background_request, options);
    }
  }

  getRoute(deeplink: string): any {
    for (let index = 0; index < this.internalDeeplinkRegex.length; index++) {
      const regExp = new RegExp(this.internalDeeplinkRegex[index], 'i');
      if (regExp.test(deeplink)) {
        return InternalRoutingService.getMatchingRoute(index, regExp.exec(deeplink).slice(1));
      }
    }

    this.trackingService.triggerLinkOpen();
    this.inAppBrowser.create(this.websiteUrl + deeplink, '_system');
    return false;
  }

  navigateToRoute(deeplink: string) {
    const route = this.getRoute(deeplink);
    if (route) {
      if (isNaN(route)) {
        return this.router.navigateByUrl(route as string);
      } else {
        this.offerService.showOfferDetailsById(route);
      }
    }
  }

  handleNotificationDeeplink(deeplink: string) {
    if (this.platform.is('android')) {
      this.handleRouting({
        action: Action.DEEPLINK,
        deeplink
      });
    } else {
      const args = {};
      const queryString = deeplink.replace(this.androidScheme, '');
      const pairs = queryString.split('&');
      for (const i in pairs) {
        if (pairs[i]) {
          const split = pairs[i].split('=');
          args[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
        }
      }
      this.checkDeeplinkMatch(queryString, args);
    }
  }

  async handleVariableDeeplink(args: AnyKey) {
    if (args.hasOwnProperty('folder')) {
      // Open folder
      this.api.setScreenId('ExternalLink', '');


      if (args.regional && !this.api.isLocationSet()) {
        this.interactionService.showAlert({
          header: 'folder.setLocation',
          subHeader: 'folder.needsLocation',
          buttons: [
            {
              text: 'global.cancel',
              handler: () => {
                this.interactionService.dismiss();
              }
            },
            {
              text: 'global.ok',
              handler: () => {
                this.router.navigate([Variables.Pages.Location], {state: {open_folder_edition_id: args.folder}});
              }
            }]
        });
      } else {
        const folder = await this.api.getFolderEdition(args.folder).toPromise();
        const params: any = {};
        if (args.page) {
          params.p = args.page;
        }
        this.router.navigate(['/', Variables.Pages.Folder, folder.folder_version_id, params]);
      }
    } else if (args.hasOwnProperty('offer')) {
      this.api.setScreenId('ExternalLink', '');
      const splashSubscription = this.splashScreenService.onDismiss.asObservable()
        .subscribe(hidden => {
          if (hidden) {
            // Open offer
            this.offerService.showOfferDetailsById(args.offer);
            splashSubscription.unsubscribe();
          }
        });
    } else if (args.hasOwnProperty('retailer') || args.hasOwnProperty('retailerFolder')) {
      // Open retailer
      // TODO: open detail pages with ID, not permaname
      const retailerResponse = await this.api.getRetailer(args.retailer ? args.retailer : args.retailerFolder).toPromise();
      this.router.navigate(['/', Variables.Pages.Tabs.Children.Retailers, retailerResponse.retailer_permaname]);
    } else if (args.hasOwnProperty('category')) {
      // Open category
      // TODO: open detail pages with ID, not permaname
      const categoryResponse = await this.api.getCategory(args.category).toPromise();
      this.router.navigateByUrl(['/', Variables.Pages.Tabs.Children.Categories, categoryResponse.category_permaname].join('/'));
    }
  }

  checkDeeplinkMatch(queryString: string, args: AnyKey) {
    const pages = Variables.Pages;
    let route;
    switch (queryString) {
      case 'home' :
        route = pages.HomeFolders;
        break;
      case 'search' :
        route = pages.HomeSearch;
        break;
      case 'lists' :
        route = pages.HomeLists;
        break;
      case 'account' :
        route = pages.HomeSettings;
        break;
      case 'folders' :
        route = pages.HomeFolders + '/' + pages.Tabs.Children.Folders;
        break;
      case 'offers' :
        route = pages.HomeFolders + '/' + pages.Tabs.Children.Offers;
        break;
      case 'setLocation' :
        route = pages.Location;
        break;
      default :
        this.handleVariableDeeplink(args);
        break;
    }

    if (route) {
      this.router.navigateByUrl(route);
    }
  }

  private async closeAllModals() {
    const topModal = await this.modalCtrl.getTop();
    if (topModal) {
      await this.modalCtrl.dismiss();
      setTimeout(() => this.closeAllModals(), 250);
    }
    return;
  }
}
