import {Injectable} from '@angular/core';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private firebase: FirebaseX) {
  }

  requestPermission() {
    return this.firebase.grantPermission();
  }

  hasPermission() {
    return this.firebase.hasPermission();
  }

  onNotification() {
    return this.firebase.onMessageReceived();
  }

  getToken() {
    return this.firebase.getToken();
  }

  onTokenChange() {
    return this.firebase.onTokenRefresh();
  }

  subscribeTo(topic: string) {
    return this.firebase.subscribe(topic);
  }

  unsubscribeFrom(topic: string) {
    return this.firebase.unsubscribe(topic);
  }

  setBadge(amount: number) {
    return this.firebase.setBadgeNumber(amount);
  }

}
