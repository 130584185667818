import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {from, Observable} from 'rxjs';
import {Variables} from '../constants/variables';
import {StorageService} from '../services/storage.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {PreferencesService} from '../services/preferences.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateOnboardingGuard implements CanActivate {

  constructor(private storageService: StorageService,
              private preferencesService: PreferencesService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    let onboardingShownOnNativeApp = null;
    return from(this.preferencesService.getPreference(Variables.NativeApp.UserPreferences.OnboardingShown))
      .pipe(
        tap(value => onboardingShownOnNativeApp = value),
        switchMap(() => this.storageService.load(Variables.Storage.FirstRun)),
        map(firstRun => {
          if (firstRun !== null || onboardingShownOnNativeApp !== null) {
            return this.router.parseUrl('/home');
          }
          return true;
        })
      );
  }
}
