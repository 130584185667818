import { Component, Input } from '@angular/core';
import { Store } from '../../constants/interfaces';

@Component({
  selector: 'app-store-highlighted',
  templateUrl: './store-highlighted.component.html',
  styleUrls: ['./store-highlighted.component.scss'],
})
export class StoreHighlightedComponent {

  @Input()
  store: Store;

  constructor() { }

}
