import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {Variables} from '../constants/variables';
import { Observable} from 'rxjs';
import {
    AnyKey,
    AppProfile,
    Folder,
    FolderApiResponse,
    FolderHistory,
    Location,
    Offer,
    OpinionForm,
    Retailer,
    ScreenPreferences,
    ScreenSection
} from '../constants/interfaces';
import {LocationService} from './location.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {StorageService} from './storage.service';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {Device} from '@ionic-native/device/ngx';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private requestBody: ScreenPreferences = {
        country_code: 'NL'
    };

    private currentScreenId: string;

    constructor(private restAngular: Restangular,
                private locationService: LocationService,
                private storageService: StorageService,
                private appVersion: AppVersion,
                private device: Device,
                private http: HttpClient) {

        this.locationService.getLocationChange()
            .subscribe((location: Location) => {
                if (location && location.postalCode) {
                    this.requestBody.postal_code = location.postalCode;
                } else {
                    delete this.requestBody.postal_code;
                }
            });



    }

    getScreen(screenName: string): Observable<any> {
        return this.storageService.load(Variables.Storage.FavoriteRetailers).pipe(
            map((retailers: Retailer[]) => {
                const retailerIds: number[] = [];
                if (retailers) {
                    for (const retailer of retailers) {
                        retailerIds.push(retailer.retailer_id);
                    }
                }
                return retailerIds;
            }),
            switchMap((favoriteRetailerIds: number[]) => {
                if (favoriteRetailerIds) {
                    this.requestBody.favorite_retailer_ids = favoriteRetailerIds;
                    return this.restAngular.one(Variables.Api.Screens, screenName).post(null, this.requestBody);
                }
            }),
            tap((result: any) => this.setScreenId(result.screen.id, result.screen.title)),
        );
    }

    getCategories() {
        return this.restAngular.all(Variables.Api.Categories).get('')
            .pipe(
                map((result: any) => {
                    return result.categories;
                })
            );
    }

    getCategory(categoryId: number) {
        return this.restAngular.one(Variables.Api.Categories, categoryId).get()
            .pipe(
                map((result: any) => {
                    return result.category;
                })
            );
    }

    getCategoryRetailers(categoryPermaname: string) {
        return this.restAngular.one(Variables.Api.Screens, Variables.Api.Categories)
            .one(categoryPermaname, Variables.Api.Retailers)
            .post(null, this.requestBody)
            .pipe(
                map((result: any) => {
                    return result.screen;
                })
            );
    }

    getRetailers(params?: {}) {
        return this.restAngular.all(Variables.Api.Retailers).get('', params)
            .pipe(
                map((result: any) => {
                    return result.retailers;
                })
            );
    }

    getRetailer(retailerID: number): Observable<Retailer> {
        return this.restAngular.one(Variables.Api.Retailers, retailerID).get()
            .pipe(
                map((result: any) => {
                    return result.retailer;
                })
            );
    }

    getFolder(folderId: number, includeOffers: boolean = true): Observable<FolderApiResponse> {

        const queryParams = includeOffers ? {include_offers: true} : null;

        return this.restAngular.one(Variables.Api.Folders, folderId).get(queryParams);
    }

    getFolderEdition(folderEditionId: number): Observable<Folder> {
        const queryParams = this.requestBody.postal_code ? { postal_code: this.requestBody.postal_code } : null;
        return this.restAngular.one(Variables.Api.FolderEditions, folderEditionId).get(queryParams)
          .pipe(
            map((result: FolderApiResponse) => result.folder)
          );
    }

    search(query: string): Observable<Offer[]> {
        return this.restAngular.one(Variables.Api.Search).get({query, country_code: 'NL'});
    }

    getDetailScreenSections(path: string, value: any): Observable<ScreenSection[]> {
        return this.restAngular
            .one(path, value)
            .post(Variables.Api.Details, this.requestBody).pipe(
                tap((result: any) => this.setScreenId(result.screen.id, result.screen.title)),
                map((result: any) => {
                    const sections: ScreenSection[] = [];
                    result.screen.sections.forEach((section: ScreenSection) => {
                        if (section.platform_visibility.indexOf(Variables.ScreenSections.ForMobile) !== -1) {
                            sections.push(section);
                        }
                    });
                    return sections;
                })
            );
    }

    getSearchResults(query: string) {
        const params: any = {query};
        if (this.requestBody.country_code) {
            params.country_code = this.requestBody.country_code;
        }
        if (this.requestBody.postal_code) {
            params.postal_code = this.requestBody.postal_code;
        }
        return this.restAngular.one(Variables.Api.Search).get(params);
    }

    getAppConfig() {
        return this.restAngular.one(Variables.Api.Config).get(this.requestBody);
    }

    sendOpinionForm(form: OpinionForm): Observable<any> {
        return this.restAngular.one(Variables.Api.Contact).post(null, form);
    }

    getFolderSuggestions(folder: Folder) {
        return this.storageService.load(Variables.Storage.FolderIDsRead).pipe(
            switchMap((foldersHistory: FolderHistory[]) => {
                if (!foldersHistory) {
                    foldersHistory = [];
                }
                return this.restAngular.one(Variables.Api.Folders, folder.folder_version_id).one(Variables.Api.Suggestions).customPUT({
                    history: foldersHistory
                });
            }),
            map((result: any) => result.folders),
            map(folders => folders.slice(0, 15))
        );
    }

    getCloseByStores(retailerId: number, limit: number = 50) {
        if (!this.requestBody.postal_code) {
            throw Variables.ErrorMessages.PostalCodeUnkown;
        }
        return this.restAngular.one(Variables.Api.Retailers, retailerId).one(Variables.Api.Stores).get({
            postal_code: this.requestBody.postal_code,
            limit
        })
            .pipe(
                map((response: any) => response.stores)
            );
    }

    getOffer(offerId: number | string) {
        return this.restAngular.one(Variables.Api.Offer, offerId).get().pipe(
          map((result: any) => {
              if (!result.offer.valid_label_short) {
                  result.offer.valid_label_short = 'Verlopen';
              }
              if (!result.offer.valid_label_long) {
                  result.offer.valid_label_long = 'Aanbieding is verlopen';
              }
              return result.offer;
          })
        );
    }

    getKeywordOffers(keyword: string) {
        return this.restAngular.one(Variables.Api.Screens, Variables.Api.Keywords).one(keyword).post(Variables.Api.Offer, this.requestBody)
            .pipe(
                tap((result: any) => this.setScreenId(result.screen.id, result.screen.title))
            );
    }

    getLocalOffers() {
        return this.restAngular.one(Variables.Api.Screens, Variables.Api.LocalOffers).post('', this.requestBody)
            .pipe(
                tap((result: any) => this.setScreenId(result.screen.id, result.screen.title))
            );
    }

    getFoldersByCategory(categoryPermaname: string) {
        return this.restAngular.one(Variables.Api.Screens, Variables.Api.AllFolders).one(categoryPermaname).post(null, this.requestBody);
    }

    addToFavorites(retailerId: number) {
        // TODO Uncomment after user accounts are supported
        // return this.restAngular.one(Variables.Api.FavoriteRetailers, retailerId).put();
    }

    removeFromFavorites(retailerId: number) {
        // TODO Uncomment after user accounts are supported
        // return this.restAngular.one(Variables.Api.FavoriteRetailers, retailerId).remove();
    }

    getCoupons(retailerId?: number) {
        const params: AnyKey = {};

        if (this.requestBody.postal_code) {
            params.postal_code = this.requestBody.postal_code;
        }
        if (retailerId) {
            params.retailerId = retailerId;
        }
        return this.restAngular.one(Variables.Api.Coupons).get(params)
            .pipe(
                map((res: any) => res.offers)
            );
    }

    sendStats(url: string, params: AnyKey) {
        return this.http.post(url, null, {
            params
        });
    }

    syncProfile(url: string, profile: AppProfile) {
        const body: any = {
            deviceId: profile.device_id,
            notificationToken: profile.notification_token_firebase,
            appPlatform: profile.app_platform.toLowerCase(),
            appVersion: profile.app_version,
            deviceCategory: profile.device_category,
            deviceModel: profile.device_model,
            osVersion: profile.os_version
        };
        if (profile.postal_code) {
            body.postalCode = profile.postal_code;
        }
        return this.http.put(url, body, {
            headers: {
                'X-API-key': environment.api.authentication.apiKey
            }
        });
    }

    setScreenId(screenId: string, title: string) {
        if (screenId !== 'offer_details') {
            this.currentScreenId = screenId + ' - ' + title;
        }
    }

    getScreenId() {
        return this.currentScreenId;
    }

    isLocationSet(): boolean {
        return !!this.requestBody.postal_code;
    }
}
