import {Component, Input } from '@angular/core';
import {OverviewLink, ScreenSection} from '../../constants/interfaces';
import { InternalRoutingService } from '../../services/internal-routing.service';
import {TrackingService} from '../../services/tracking.service';

@Component({
    selector: 'app-total-overview',
    templateUrl: './total-overview.component.html',
    styleUrls: ['./total-overview.component.scss'],
})
export class TotalOverviewComponent {

    @Input() readonly overviewLink: OverviewLink;

    @Input() readonly section: ScreenSection;

    constructor(private internalRoutingService: InternalRoutingService,
                private trackingService: TrackingService) {
    }

    goToLink() {
        this.trackingService.triggerOverviewCard(this.section.id, this.section.section_header.title);
        this.internalRoutingService.handleRouting(this.overviewLink);
    }
}
