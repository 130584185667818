import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SectionHeadingComponent } from './section-heading/section-heading.component';
import { FolderRowComponent } from './folder-row/folder-row.component';
import { TeaserComponent } from './teaser/teaser.component';
import { ShortcutsRowComponent } from './shortcuts-row/shortcuts-row.component';
import { OfferRowComponent } from './offer-row/offer-row.component';
import { FolderHighlightedModule } from '../folder-highlighted/folder-highlighted.module';
import { ImageSizeModule } from '../../pipes/image-size/image-size.module';
import { CustomIconModule } from '../custom-icon/custom-icon.module';
import { CustomIconBtnModule } from '../custom-icon-btn/custom-icon-btn.module';
import { OfferHighlightedModule } from '../offer-highlighted/offer-highlighted.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { LocalOffersComponent } from './local-offers/local-offers.component';
import { OfferCarouselComponent } from './offer-carousel/offer-carousel.component';
import { KeywordsCarouselComponent } from './keywords-carousel/keywords-carousel.component';
import { KeywordHighlightedModule } from '../keyword-highlighted/keyword-highlighted.module';
import { KeywordsRowComponent } from './keywords-row/keywords-row.component';
import { LoadMoreComponent } from '../load-more/load-more.component';
import { RetailerHighlightedModule } from '../retailer-highlighted/retailer-highlighted.module';
import { RetailerRowComponent } from './retailer-row/retailer-row.component';
import { CustomTextComponent } from './custom-text/custom-text.component';
import { FolderCarouselComponent } from './folder-carousel/folder-carousel.component';
import { StoreCarouselComponent } from './store-carousel/store-carousel.component';
import { StoreHighlightedComponent } from '../store-highlighted/store-highlighted.component';
import { RetailerCarouselComponent } from './retailer-carousel/retailer-carousel.component';
import { ProgressiveImgModule } from '../../directives/progressive-img/progressive-img.module';
import {TeaserRowComponent} from './teaser-row/teaser-row.component';
import { TotalOverviewModule } from '../total-overview/total-overview.module';
import {LoadAllComponent} from "../load-all/load-all.component";
import {AdvertisementRowComponent} from './advertisement-row/advertisement-row.component';
import {AdvertisementModule} from '../advertisement/advertisement.module';

const components = [
  SectionHeadingComponent,
  FolderRowComponent,
  FolderCarouselComponent,
  TeaserRowComponent,
  TeaserComponent,
  ShortcutsRowComponent,
  OfferRowComponent,
  LocalOffersComponent,
  OfferCarouselComponent,
  KeywordsCarouselComponent,
  KeywordsRowComponent,
  LoadMoreComponent,
  LoadAllComponent,
  RetailerRowComponent,
  RetailerCarouselComponent,
  CustomTextComponent,
  StoreCarouselComponent,
  StoreHighlightedComponent,
  AdvertisementRowComponent
];

const dependencies = [
  FolderHighlightedModule,
  ImageSizeModule,
  CustomIconModule,
  CustomIconBtnModule,
  OfferHighlightedModule,
  KeywordHighlightedModule,
  RetailerHighlightedModule,
  ProgressiveImgModule
];

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    FormsModule,
    ...dependencies,
    TotalOverviewModule,
    AdvertisementModule
  ],
  declarations: components,
  exports: components
})
export class ScreenSectionsModule {
}
