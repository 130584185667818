import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {FolderHighlightedComponent} from './folder-highlighted.component';
import {ImageSizeModule} from '../../pipes/image-size/image-size.module';
import { LazyloadModule } from '../../directives/lazyload/lazyload.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule.forChild(),
        ImageSizeModule,
        LazyloadModule
    ],
    declarations: [FolderHighlightedComponent],
    exports: [FolderHighlightedComponent]
})
export class FolderHighlightedModule {
}
