import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Keyword } from '../../constants/interfaces';

@Component({
  selector: 'app-keyword-highlighted',
  templateUrl: './keyword-highlighted.component.html',
  styleUrls: ['./keyword-highlighted.component.scss'],
})
export class KeywordHighlightedComponent {

  @Input() keyword: Keyword;

  @Output() keywordClick: EventEmitter<void> = new EventEmitter();

  nActions: number;

}
