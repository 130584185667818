import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TextContrastService } from '../../../services/text-contrast.service';
import { Teaser } from '../../../constants/interfaces';
import {InternalRoutingService} from '../../../services/internal-routing.service';
import {TrackingService} from '../../../services/tracking.service';

@Component({
  selector: 'app-teaser',
  templateUrl: './teaser.component.html',
  styleUrls: ['./teaser.component.scss'],
})
export class TeaserComponent {

  @Input() teaser: Teaser;

  @Input() teaserIndex: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private trackingService: TrackingService,
              public textContrastService: TextContrastService,
              private internalRouting: InternalRoutingService) {
  }


  clickedTeaser(teaser: Teaser) {
    this.trackingService.triggerTeaserOpen(teaser.title, 'double', this.teaserIndex);
    this.internalRouting.handleRouting(teaser);
  }
}
