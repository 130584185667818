import {Input, OnInit} from '@angular/core';
import { ItemRow, ScreenSection } from '../../constants/interfaces';
import { Helpers } from '../../helpers/helpers';

export class Section<T> implements OnInit {

    private visibleRows: number;

    @Input()
    config: ScreenSection;

    @Input()
    colsPerRow: number;

    items: T[];
    rows: number[] = [];
    rowsWithItems: any[] = [];
    totalRows: number[] = [];
    colSize: number;




    ngOnInit() {
        this.init();
    }


    showMore(rows: number) {
        const prevVisibleRows = this.visibleRows;
        this.visibleRows += rows;

        for(let row = prevVisibleRows; row < this.visibleRows; row++) {
            this.rows.push(row);
        }
    }

    resetValues(colsPerRow: number) {
        this.colsPerRow = colsPerRow;
        this.init();
    }

    private init() {
        this.items = this.config.items;
        this.visibleRows = this.config.max_rows || this.items.length / this.colsPerRow;

        this.rows = Helpers.range(0, this.visibleRows);
        this.totalRows = Helpers.range(0, this.items.length / this.colsPerRow);
        this.colSize = 12 / this.colsPerRow;

        this.rowsWithItems = [];
        this.rows.forEach((rowNum) => {
            this.rowsWithItems.push(this._getItemsForRow(rowNum));
        });
    }

    getItemsForRow(row: number): T[] {
        return this.rowsWithItems[row];
    }

    private _getItemsForRow(row: number): T[] {
        return this.items ? this.items.slice(row * this.colsPerRow, row * this.colsPerRow + this.colsPerRow) : [];
    }
}
