import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Keyword } from '../../../constants/interfaces';
import { Helpers } from '../../../helpers/helpers';

@Component({
  selector: 'app-keywords-carousel',
  templateUrl: './keywords-carousel.component.html',
  styleUrls: ['./keywords-carousel.component.scss'],
})
export class KeywordsCarouselComponent implements  OnInit {


  @Input() keywords: Keyword[];
  @Input() maxRows: number = 3;

  @Output() keywordClick: EventEmitter<Keyword> = new EventEmitter();

  colSize: number = 5;

  rows: Keyword[][];

  constructor() {}

  ngOnInit(): void {


    const itemsPerRow = Math.ceil(this.keywords.length / this.maxRows);
    this.colSize = (12 / (itemsPerRow-1)) -1;

    this.rows = Helpers.arrayChunk(this.keywords, itemsPerRow);

  }

  keywordClicked(keyword: Keyword) {
    this.keywordClick.emit(keyword);
  }
}
