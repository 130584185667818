import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {ModalController} from '@ionic/angular';
import { Category, Folder, List, Offer, Retailer } from '../../constants/interfaces';
import {SearchService} from '../../services/search.service';
import {Subscription} from 'rxjs';
import {FavoriteRetailersService} from '../../services/favorite-retailers.service';
import * as uniqid from 'uniqid';
import {Variables} from '../../constants/variables';
import {Router} from '@angular/router';
import {TrackingService} from '../../services/tracking.service';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {UserAgent} from '@ionic-native/user-agent/ngx';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {

    @ViewChild('search')
    private searchBar: any;

    readonly pages = Variables.Pages;

    // Used to inject from outside
    keyword: string;
    // Used to inject from list page
    list: List;
    keywordInList: boolean;

    query: string;

    isTyping: boolean;
    hasResults: boolean;
    isLoading: boolean;

    results: {
        retailers: Retailer[];
        favorite_offers: Offer[];
        offers: Offer[];
        folders: Folder[];
    };

    searchSubscription: Subscription;
    categories: Category[];
    categoriesSubscription: Subscription;

    items: {
        id: string;
        rowType: string;
        height: number;
        content: string | Retailer | Offer | Folder;
        last?: boolean;
    }[];

    errorStatusCode: number;

    isTablet: boolean;

    constructor(private api: ApiService,
                private router: Router,
                public modalCtrl: ModalController,
                private trackingService: TrackingService,
                private favoriteService: FavoriteRetailersService,
                public keyboard: Keyboard,
                private userAgent: UserAgent,
                public searchService: SearchService) {
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
        this.categoriesSubscription.unsubscribe();
    }

    getCategories() {
        this.categoriesSubscription = this.api.getCategories()
          .subscribe(categories => {
              this.errorStatusCode = null;
              this.categories = categories;
          }, error => this.errorStatusCode = error.status);
    }

    ionViewDidEnter() {
        if (!this.keyword) {
            this.searchBar.setFocus();
        }

        if (this.keyword) {
            this.query = this.keyword;
            this.isTyping = true;
            this.startSearch();
        }
        this.checkIfKeywordExistsInList();
        this.getCategories();

        this.userAgent.get()
          .then(userAgent => this.isTablet = userAgent.includes('Tablet'));
    }

    closeSearch() {
        this.modalCtrl.dismiss();
        if (this.query) {
            this.trackingService.triggerSearchQuery(this.query);
        }
    }

    startTyping() {
        this.isTyping = true;
    }

    startSearch() {
        this.isLoading = true;
        this.isTyping = !!this.query;
        if (this.isTyping) {
            if (this.searchSubscription) {
                this.searchSubscription.unsubscribe();
            }

            this.searchSubscription = this.api.getSearchResults(this.query).pipe(
              finalize(() => this.isLoading = false)
              ).subscribe(result => {
                    this.results = {
                        retailers: result.retailers,
                        offers: result.offers.filter(offer => !this.favoriteService.isFavorite(offer.retailer_id)),
                        favorite_offers: result.offers.filter(offer => this.favoriteService.isFavorite(offer.retailer_id)),
                        folders: result.folders
                    };
                    this.setListItems();
                    this.hasResults = !!this.results.favorite_offers.length || !!this.results.offers.length ||
                      !!this.results.retailers.length;
                    this.searchService.saveKeyword(this.query);
                    this.checkIfKeywordExistsInList();
                    this.errorStatusCode = null;
                }, error => {
                    this.errorStatusCode = error.status;
                });
        } else {
            this.items = [];
        }
    }

    quickSearch(keyword: string) {
        this.query = keyword;
        this.isLoading = true;
    }

    trackItem(index: number, item) {
        return item.id;
    }

    getVirtualScrollItemHeight(item) {
        return item.height;
    }

    clickedRetailer(retailer: Retailer) {
        this.trackingService.triggerSearchQuery(this.query, null, retailer);
        this.modalCtrl.dismiss();
        this.router.navigate(['/', this.pages.Tabs.Children.Retailers, retailer.retailer_permaname]);
    }

    clickedFolder(folder: Folder) {
        this.trackingService.triggerSearchQuery(this.query, null, null, folder);
        this.modalCtrl.dismiss();
        this.router.navigate(['/', this.pages.Folder, folder.folder_version_id]);
    }

    private setListItems() {
        this.items = [];
        if (this.results.retailers.length) {
            this.items.push({
                id: uniqid(),
                rowType: 'heading',
                height: 51,
                content: 'search.shops'
            });

            this.results.retailers.forEach((retailer, index) => this.items.push({
                id: uniqid(),
                rowType: 'retailer',
                height: 65,
                content: retailer,
                last: index === this.results.retailers.length - 1
            }));
        }

        // if (this.results.folders.length) {
        //   this.items.push({
        //     id: uniqid(),
        //     rowType: 'heading',
        //     height: 51,
        //     content: 'search.folders'
        //   });
        //
        //   this.results.folders.forEach(folder => this.items.push({
        //     id: uniqid(),
        //     rowType: 'folder',
        //     height: 65,
        //     content: folder
        //   }));
        // }

        if (this.results.favorite_offers.length) {
            this.items.push({
                id: uniqid(),
                rowType: 'heading',
                height: 51,
                content: 'search.offersOfFavorites'
            });

            this.results.favorite_offers.forEach((offer, index) => this.items.push({
                id: uniqid(),
                rowType: 'offer',
                height: 143,
                content: offer,
                last: index === this.results.favorite_offers.length - 1
            }));
        }

        if (this.results.offers.length) {
            this.items.push({
                id: uniqid(),
                rowType: 'heading',
                height: 51,
                content: this.results.favorite_offers.length ? 'search.offersOfOthers' : 'search.offers'
            });

            this.results.offers.forEach((offer, index) => this.items.push({
                id: uniqid(),
                rowType: 'offer',
                height: 143,
                content: offer,
                last: index === this.results.offers.length - 1
            }));
        }
    }

    private checkIfKeywordExistsInList() {
        if (this.list && this.query) {
            this.keywordInList = this.list.keywords.includes(this.query) || this.list.crossedKeywords.includes(this.query);
        }
    }

    trackOfferClick(offer: Offer) {
        this.trackingService.triggerSearchQuery(this.query, offer);
    }

    openCategoryLink(link) {
        this.modalCtrl.dismiss().then(() => {
            this.router.navigateByUrl(link);
        });
    }
}
