import {Injectable} from '@angular/core';

declare var cordova: any;

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  initialize(fileName: string) {
    return new Promise((resolve, reject) =>
      cordova.plugins.CordovaPreferences.initializePreferences(fileName, resolve, reject));
  }

  getPreference(key: string): Promise<any> {
    return new Promise(resolve => {
      try {
        cordova.plugins.CordovaPreferences.getPreference(key, value => {
          resolve(value.hasOwnProperty(key) && value[key] ? value[key] : null);
        }, () => resolve(null));
      } catch {
        resolve(null);
        console.log('Preferences not available');
      }
    });
  }

}
