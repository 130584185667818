import { Component, EventEmitter, Input, Output } from '@angular/core';
import {Retailer} from '../../../constants/interfaces';

@Component({
  selector: 'app-retailer-carousel',
  templateUrl: './retailer-carousel.component.html',
  styleUrls: ['./retailer-carousel.component.scss'],
})
export class RetailerCarouselComponent {

  @Input()
  retailers: Retailer[];

  @Output()
  retailerClicked: EventEmitter<any> = new EventEmitter();

  clickedRetailer(retailer: Retailer) {
    this.retailerClicked.emit(retailer);
  }
}
