import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PlaceholderService {

    getLogo(ev: CustomEvent) {
      console.error('DEPRECATED');
        (ev.target as any).src = './assets/images/logo.jpg';
    }

}
