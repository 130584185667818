import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ListService} from './list.service';
import {InteractionService} from './interaction.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  readonly searchKey = 'search_history';

  private history: BehaviorSubject<any> = new BehaviorSubject([]);

  get getSearchHistory() {
    return this.history;
  }


  constructor(private listService: ListService,
              private interactionService: InteractionService,
              private storage: StorageService
              ) {
    this.initializeKeywords();
  }

  private async initializeKeywords() {
    const res = await this.storage.load(this.searchKey).toPromise();
    if (res) {
      this.history.next(res);
    }
  }

  saveKeyword(keyword: string) {
    let keywords = this.history.getValue();
    const index = keywords.map(k => k.toLowerCase()).indexOf(keyword.toLowerCase());
    if (keywords.includes(keyword)) {
      keywords.splice(index, 1);
    }
    keywords.unshift(keyword);
    keywords = keywords.slice(0, 10);
    this.storage.save(this.searchKey, keywords);
  }

  emptyKeywords() {
    this.history.next([]);
    this.storage.save(this.searchKey, []);
  }

  addKeywordToList(keyword: string) {
    this.interactionService.showAlert({
      header: 'search.selectList',
      inputs: this.listService.getLists.getValue().map(list => {
        return {
          name: list.id,
          type: 'radio',
          label: list.name,
          value: list.id
        };
      }) as any[],
      buttons: [
        {
          text: 'global.cancel',
          role: 'cancel'
        },
        {
          text: 'global.select',
          handler: listId => {
            if (!listId) {
              this.interactionService.showToast('search.selectList');
              return false;
            }
            const list = this.listService.getSingle(listId);
            if (list.keywords.indexOf(keyword) === -1) {
              list.keywords.push(keyword);
            }
            this.listService.editList(list);
            this.interactionService.showToast('search.addedToList');
          }
        }
      ]
    });
  }

}
