import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {ErrorHandler, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';

interface ResponseWithRequest extends HttpErrorResponse {
  request?: {
    body?: any;
    method: string;
  };
}

interface IonicError {
  line: number;
  column: number;
  sourceURL: string;
}

@Injectable()
export class IonicErrorHandler implements ErrorHandler {

  constructor(private firebase: FirebaseX) {
  }

  handleError(error: string | HttpErrorResponse | IonicError) {
    if (environment.production) {
      if (error instanceof HttpErrorResponse) {
        if (navigator.onLine) {
          // Handle Http Error
          console.log('Crashlytics: HTTP error logged');
          const withBody = (error as ResponseWithRequest).request.body ?
            ` with body: ${JSON.stringify((error as ResponseWithRequest).request.body)}` :
            '';
          this.firebase.logError(`HTTP ${error.status}: ${(error as ResponseWithRequest).request.method} ${error.url}${withBody}`);
        }
      } else {
        // Handle Angular / JS error like 'undefined object'
        console.log('Crashlytics: JavaScript error logged');
        if (typeof error === 'string') {
          this.firebase.logError(error);
        } else {
          const sourceUrl = (error.sourceURL) ? error.sourceURL.replace('ionic://www.reclamefolder.nl', '') : 'unknown';
          const column = (error.column) ? error.column : '?';
          this.firebase.logError(`JS error in ${sourceUrl}:${column}`);
        }
      }
    }

    console.error(error);
  }

}
