import { Component, Input, OnInit } from '@angular/core';
import {Retailer} from '../../constants/interfaces';
import {PlaceholderService} from '../../services/placeholder.service';

@Component({
  selector: 'app-retailer-highlighted',
  templateUrl: './retailer-highlighted.component.html',
  styleUrls: ['./retailer-highlighted.component.scss'],
})
export class RetailerHighlightedComponent implements OnInit{

  @Input()
  retailer: Retailer;

  retailerImageURL: string;

  constructor(public placeholder: PlaceholderService) {
  }

  ngOnInit(): void {
    this.initRetailerImageURL();
  }

  // TODO Needs refactoring
  initRetailerImageURL() {
    if (this.retailer.assets) {
      this.retailerImageURL = (
        this.retailer.assets.retailer_logo_circle_image ? this.retailer.assets.retailer_logo_circle_image
          : this.retailer.assets.retailer_logo_image
      ).image_url;
    } else {
      this.retailerImageURL = (
        this.retailer.retailer_logo_circle_image ? this.retailer.retailer_logo_circle_image
          : this.retailer.retailer_logo_image
      ).image_url;
    }
  }
}
