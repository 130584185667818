import {Component, Input} from '@angular/core';
import {InternalRoutingService} from '../../../services/internal-routing.service';
import {TrackingService} from '../../../services/tracking.service';

@Component({
  selector: 'app-section-heading',
  templateUrl: './section-heading.component.html',
  styleUrls: ['./section-heading.component.scss'],
})
export class SectionHeadingComponent {

  @Input() heading: any;

  @Input() sectionId: string;

  constructor(private internalRouting: InternalRoutingService,
              private trackingService: TrackingService) {
  }

  goToLink() {
    this.trackingService.triggerOverviewLink(this.sectionId, this.heading.title);
    this.internalRouting.handleRouting(this.heading);
  }
}
