import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ImageSizeModule} from '../../pipes/image-size/image-size.module';
import {RetailerHighlightedComponent} from './retailer-highlighted.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        ImageSizeModule
    ],
    declarations: [RetailerHighlightedComponent],
    exports: [RetailerHighlightedComponent]
})
export class RetailerHighlightedModule {
}
