import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {RFError} from '../../constants/enums';
import {ErrorMessagesService} from '../../services/error-messages.service';

@Component({
    selector: 'app-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit, OnChanges {

    @Input()
    errorStatusCode: number;

    @Input()
    errorMessage: string;

    /**
     * Forces error message to be shown.
     */
    @Input()
    showError: boolean;

    @Output()
    tryAgain = new EventEmitter();
    showTryAgian: boolean;

    description: string;
    showTitle = true;

    @Input()
    showLoader: boolean;

    constructor(private errorMessageService: ErrorMessagesService) {
    }

    ngOnInit(): void {
        this.errorMessageService.reConnect.asObservable()
            .subscribe(connected => {
                if (connected) {
                    this.tryAgain.emit();
                }
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.errorStatusCode !== undefined) {
            this.showTitle = this.errorStatusCode !== 404;
            const errorMessage =
                this.errorMessageService.getErrorMessage(this.errorStatusCode === 404 ?
                  RFError.ERROR_SCREEN_NOT_FOUND :
                  RFError.ERROR_GENERAL);
            this.description = errorMessage.message;
            this.showTryAgian = !errorMessage.connected;
            if (this.errorMessage) {
                this.description = this.errorMessage;
            }
        }
    }
}
