import { Component, Input } from '@angular/core';
import { Keyword, ScreenSection } from '../../../constants/interfaces';

@Component({
  selector: 'app-keywords-row',
  templateUrl: './keywords-row.component.html',
  styleUrls: ['./keywords-row.component.scss'],
})
export class KeywordsRowComponent {

  @Input() keywordRow: Keyword[];

  constructor() {
  }
}
