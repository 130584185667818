import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {LocationPage} from './location.page';
import {TranslateModule} from '@ngx-translate/core';
import {CustomIconModule} from '../../components/custom-icon/custom-icon.module';
import {GoogleMapsComponent} from '../../components/google-maps/google-maps.component';

const routes: Routes = [
  {
    path: '',
    component: LocationPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
    CustomIconModule
  ],
  declarations: [
    LocationPage,
    GoogleMapsComponent
  ],
  entryComponents: [
    GoogleMapsComponent
  ]
})
export class LocationPageModule {
}
