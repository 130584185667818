import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LocationPage } from '../pages/location/location.page';
import { InteractionService } from '../services/interaction.service';
import { LocationService } from '../services/location.service';

@Injectable({
  providedIn: 'root'
})
export class CanDeActivateLocationGuard implements CanDeactivate<LocationPage> {

  constructor(private interactionService: InteractionService, private locationService: LocationService) {
  }

  canDeactivate(locationPage: LocationPage,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean> {
    return new Observable(
      (observer) => {
        const lastPostalCode = this.locationService.location ? this.locationService.location.postalCode : null;

        if (locationPage.postalCodeIsValid() && lastPostalCode !== locationPage.postalCode) {
          this.interactionService.showAlert({
            header: 'location.title',
            subHeader: 'location.notSavedYet',
            buttons: [
              {
                text: 'global.no',
                handler: () => {
                  this.interactionService.dismiss();
                  observer.next(true);
                }
              },
              {
                text: 'global.save',
                handler: () => {
                  locationPage.setLocation().then(() => {
                    this.interactionService.dismiss();
                    observer.next(true);
                  });
                }
              }
            ]
          });
        } else {
          observer.next(true);
        }
      }
    );
  }
}
