export enum Action {
  DEEPLINK = 'deeplink',
  INTERNAL_DEEPLINK = 'internal_deeplink',
  BACKGROUND_REQUEST = 'background_request'
}

export enum OfferKind {
  OFFER = 'offer',
  HYPERLINK = 'hyperlink'
}

export enum Currency {
  EURO = 'EUR'
}

export enum Layout {
  HORIZONTAL = 'horizontal_listing'
}

export enum BackgroundRequest {
  SAVE_LOCAL_LIST = 'local.lists.save',
  ADD_REMOVE_FAV_TOGGLE = 'app_resources.user_favorite_retailers_url'
}

export enum Buttons {
  SAVE_TO_LIST = 'save_to_list',
  OPEN_FOLDER = 'open_folder'
}

export enum FolderLayout {
  FOLDER = 'folder_thumb_5',
  FOLDER_LOC_REQ = 'folder_location_required_5',
  PREMIUM = 'premium_folder_5',
  PREMIUM_LOC_REQ = 'premium_folder_location_required_5',
  OFFER_COUPON = 'offer_coupon_5'
}

export enum DeeplinkRouting {
  CategoryDetail = 0,
  CategoryFolders,
  CategoryOffers,
  CategoryRetailers,
  RetailerDetails,
  OfferDetails,
  KeywordDetails,
  KeywordOffers,
  Offers,
  LocalOffers,
  AllFolders,
  Favorites
}

export enum RFError {
  ERROR_SCREEN_NOT_FOUND = 0,
  ERROR_NETWORK,
  ERROR_INIT,
  ERROR_GENERAL,
  ERROR_CONTACT,
  ERROR_NO_OFFERS = 1,
}

export enum ButtonTypes {
  Favorite = 0,
  None
}

export enum Position {
  Left = 0,
  Center,
  Right
}

export enum ColSizeType {
  Folder,
  Retailer,
  Default
}

export enum BreakPoint {
  sm,
  md,
  lg,
  xl
}
