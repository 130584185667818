import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-icon-btn',
  templateUrl: './custom-icon-btn.component.html',
  styleUrls: ['./custom-icon-btn.component.scss'],
})
export class CustomIconBtnComponent implements OnInit {

  @Input()
  btnColor: string;

  @Input()
  btnClass: string;

  @Input()
  iconColor: string;

  @Input()
  iconSize: string;

  @Input()
  icon: string;

  @Input()
  btnText: string;

  @Output()
  btnClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  clickedBtn() {
    this.btnClick.emit();
  }

}
