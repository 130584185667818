import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ScreenSection} from '../../constants/interfaces';
import {InternalRoutingService} from '../../services/internal-routing.service';


@Component({
  selector: 'app-load-all-button',
  templateUrl: './load-all.component.html',
  styleUrls: ['./load-all.component.scss'],
})
export class LoadAllComponent {


  @Input() show: boolean = true;

  @Input() btnText: string;

  @Input() section: ScreenSection;


  constructor(private internalRouting: InternalRoutingService) {
  }

  onClick() {
    this.internalRouting.handleRouting(this.section.total_overview_link);
  }

}
