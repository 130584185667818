import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Variables } from '../constants/variables';
import {BehaviorSubject, from, Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {Folder, FolderHistory, FolderRead} from '../constants/interfaces';
import { FolderLayout } from '../constants/enums';

@Injectable({
  providedIn: 'root'
})
export class FolderService {

  private readFolderIDs: FolderHistory[] = [];

  private _readSubject = new BehaviorSubject([]);

  constructor(private storageService: StorageService) {
    this.storageService.load(Variables.Storage.FolderIDsRead)
      .subscribe(read => {
        if (read) {
          this.readFolderIDs = read;
          this._readSubject.next(read);
        }
      });
  }

  readFolder(folder: Folder): Observable<boolean> {
    if (this.readFolderIDs.length === 0) {
      return this.storageService.load(Variables.Storage.FolderIDsRead).pipe(
        map(folderHistory => {
          if (folderHistory) {
            this.readFolderIDs = folderHistory;
            return this.readFolderIDs.findIndex(f => f.folder_version_id === folder.folder_version_id) >= 0;
          }
          return false;
        }));
    }
    return from([this.readFolderIDs.findIndex(f => f.folder_version_id === folder.folder_version_id) >= 0]);
  }

  addReadFolder(folder: Folder | FolderRead, openedAt: Date = new Date()) {
    this.readFolderIDs.push({
      folder_edition_id: folder.folder_edition_id,
      folder_version_id: folder.folder_version_id,
      category_id: folder.category_id,
      retailer_id: folder.retailer_id,
      opened_at: openedAt
    });
    this.storageService.save(Variables.Storage.FolderIDsRead, this.readFolderIDs);
    this._readSubject.next(this.readFolderIDs);
  }

  isPremium(folder: Folder): boolean {
    return folder.preferred_layouts && (folder.preferred_layouts.toString() === FolderLayout.PREMIUM ||
      folder.preferred_layouts.toString() === FolderLayout.PREMIUM_LOC_REQ);
  }

  getFoldersRead() {
    return this._readSubject.asObservable();
  }

  getTotalFoldersRead() {
    return this.readFolderIDs.length;
  }
}
