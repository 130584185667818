import {NgModule} from '@angular/core';
import {ImageSizePipe} from './image-size.pipe';


@NgModule({
    declarations: [ImageSizePipe],
    exports: [ImageSizePipe]
})
export class ImageSizeModule {
}
