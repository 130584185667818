import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {LocationService} from '../../../services/location.service';
import {Variables} from '../../../constants/variables';
import {Offer, ScreenSection} from '../../../constants/interfaces';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-local-offers',
  templateUrl: './local-offers.component.html',
  styleUrls: ['./local-offers.component.scss'],
})
export class LocalOffersComponent implements OnInit {

  @Input() config: ScreenSection;
  @Input() offers: Offer[];

  @Input()
  location: Location;

  @Input()
  view: string;

  @Output()
  postalCodeUpdate = new EventEmitter();

  readonly views = Variables.LocalOfferViews;

  sliderOptions: any;

  loadingLocation: boolean;

  form: FormGroup;

  constructor(private locationService: LocationService,
              private router: Router) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      postalCode: new FormControl(this.locationService.location ? this.locationService.location.postalCode : null, [
        Validators.minLength(6),
        Validators.maxLength(6),
        Validators.pattern('^[1-9][0-9]{3}[a-zA-Z]{2}$'),
        Validators.required
      ])
    });

    if (!this.form.value.postalCode) {
      this.view = Variables.LocalOfferViews.NoLocation;
    } else if (this.offers) {
      if (this.form.value.postalCode && !this.offers.length) {
        this.view = Variables.LocalOfferViews.NoResults;
      } else if (!this.form.value.postalCode && !this.offers.length) {
        this.view = Variables.LocalOfferViews.Empty;
      } else {
        this.view = Variables.LocalOfferViews.Valid;
      }
    } else {
      this.view = Variables.LocalOfferViews.Valid;
    }

    this.sliderOptions = {
      spaceBetween: 16,
      preloadImages: true,
      slidesPerView: 'auto'
    };
  }

  goToLocation() {
    return this.router.navigate(['/', Variables.Pages.Location]);
  }

}
