import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';
import {AnyKey, AppProfile, Folder, Offer, Retailer} from '../constants/interfaces';
import {Appsflyer} from '@ionic-native/appsflyer/ngx';
import {environment} from '../../environments/environment';
import {Platform} from '@ionic/angular';
import {FolderService} from './folder.service';
import {OfferService} from './offer.service';
import {ApiService} from './api.service';
import {AppProfileService} from './app-profile.service';
import {LocationService} from './location.service';
import {Variables} from '../constants/variables';
import * as moment from 'moment';
import {StorageService} from './storage.service';
import {Device} from '@ionic-native/device/ngx';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {NotificationsService} from './notifications.service';
import {filter} from 'rxjs/operators';

interface Session {
  sessions?: {
    start: Date;
    end?: Date;
  }[];
  start?: string;
  actualDifference?: number;
  total?: number;
}

const NOOP = (...args) => {
};


@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  private readonly EVENTS = {
    AppsFlyer: {
      SessionStart: 'session_start',
      FolderOpened: 'af_content_view',
      FolderOpenedFifth: 'af_content_view_5_times',
      FolderOpenedTenth: 'af_content_view_10_times',
      OfferOpened: 'Offer_open',
      OfferOpenedFifth: 'Offer_open_5_times',
      OfferOpenedTenth: 'Offer_open_10_times'
    },
    Krux: {
      FolderOpened: 'Page view'
    },
    Firebase: {
      SessionStart: 'user_session_start',
      SessionEnd: 'user_session',
      FolderOpen: 'folder_open',
      FolderView: 'folder_view',
      PageOpen: 'page_open',
      PageView: 'page_view',
      OfferOpen: 'offer_open',
      DiscountCouponOpen: 'discount_coupon_open',
      OfferLink: 'offer_link',
      OfferSave: 'offer_save',
      ContentShare: 'content_share',
      LinkOpen: 'link_open',
      UserSearchQuery: 'user_search_query',
      ConfigRefresh: 'config_refresh',
      TabOpen: 'tab_open',
      TeaserOpen: 'teaser_open',
      KeywordOpen: 'keyword_open',
      CategoryOpen: 'category_open',
      RetailerOpen: 'retailer_open',
      RetailerFavoriteToggle: 'retailer_save',
      KeywordSavedToList: 'query_save',
      LoyaltyCardOpen: 'loyalty_card_open',
      LoyaltyCardSave: 'loyalty_card_save',
      UserLocationSave: 'user_location_save',
      NotificationsSaved: 'notification_settings_save',
      LoadMore: 'load_more',
      OverviewLink: 'overview_link',
      OverviewCard: 'overview_card'
    }
  };

  private sessions: {
    [key: string]: {
      start: Date;
      end?: Date;
    }[];
  } = {};

  private sessionCount: {
    [key: string]: number;
  } = {};

  private statsUrl: string;

  private appsFlyerInitialized: boolean;

  // Used for billing in statsReceiver
  private folderOpenedInThisSession: {
    [folderId: number]: boolean;
  } = {};

  private isBrowser: boolean;

  private userLocationSource: string;
  private notificationSource: string;

  // private firebaseAnalytics = {
  //   logEvent: NOOP,
  //   setUserProperty: NOOP,
  //   setEnabled: NOOP
  // };

  constructor(private configService: ConfigService,
              private appsFlyer: Appsflyer,
              private folderService: FolderService,
              private apiService: ApiService,
              private offerService: OfferService,
              private profileService: AppProfileService,
              private locationService: LocationService,
              private device: Device,
              private firebaseAnalytics: FirebaseX,
              private storageService: StorageService,
              private platform: Platform,
              private notificationService: NotificationsService) {
    this.platform.ready()
      .then(() => {
        this.isBrowser = !this.platform.is('cordova');
        this.initializeAppStatusListeners();
      });
  }

  private initializeAppStatusListeners() {
    // Retrieve session count from storage
    this.storageService.load(Variables.Storage.SessionCount)
      .subscribe(sessions => {
        if (sessions) {
          this.sessionCount = sessions;
        }
      });

    // Retrieve sessions from storage
    this.storageService.load(Variables.Storage.Sessions)
      .subscribe(sessions => {
        if (sessions) {
          this.sessions = sessions;
          this.checkEndOfSession(true);
        }
      });

    // Listen to pause event / triggers when app goes to background
    this.platform.pause
      .subscribe(() => {
        for (const session in this.sessions) {
          if (this.sessions.hasOwnProperty(session)) {
            this.sessions[session][this.sessions[session].length - 1].end = new Date();
          }
        }
        this.storageService.save(Variables.Storage.Sessions, this.sessions);
      });

    // Listen to resume event / triggers when app goes to foreground
    this.platform.resume
      .subscribe(() => {
        this.notificationService.setBadge(0);
        this.checkEndOfSession();
      });

    // Listen to location changes and save it to Firebase
    this.locationService.getLocationChange()
      .subscribe(location => {
        if (location) {
          if (location.postalCode) {
            this.setUserProperties({
              stored_zip: location.postalCode
            });
          }

          if (location.city) {
            this.setUserProperties({
              stored_city: location.city
            });
          }

          if (location.mode) {
            this.setUserProperties({
              stored_loc_src: location.mode
            });
          }
        }
      });

    // Store UUID in Firebase
    this.setUserProperties({
      device_id: this.device.uuid,
      account_status: 'no',
      app_id: 'reclamefolder'
    });

    // TODO: Fix this. Needed listener for share offer
    this.offerService.shareSubject.pipe(
      filter(offer => !!offer)
    ).subscribe(offer => this.triggerOfferShared(offer));

    this.offerService.offerHyperlinkSubject.pipe(
      filter(offer => !!offer)
    ).subscribe(offer => {
      this.triggerLinkOpen(offer);
      this.triggerOfferOpenWebsite(offer);
    });
  }

  private async initializeTracking() {
    console.log('Initializing all tracking');
    try {
      if (!this.statsUrl) {
        this.statsUrl = await this.configService.getValue('app_resources', 'v1_stats_url');
        console.log('Stats url initialized');
      }

      // App only statistics
      if (this.platform.is('cordova')) {
        if (!this.appsFlyerInitialized) {
          const appsFlyer = await this.appsFlyer.initSdk({
            appId: this.platform.is('ios') ?
              'nl.aquilasoft.reclamefolder' :
              'nl.onlineretailservice.reclamefolderandroid',
            devKey: environment.appsFlyer,
            isDebug: false,
            onInstallConversionDataListener: true
          });
          if (appsFlyer && appsFlyer.data && appsFlyer.data.af_message) {
            this.setUserProperties({
              attr_campaign: appsFlyer.data.af_message
            });
          }
          if (appsFlyer && appsFlyer.data && appsFlyer.data.af_status) {
            this.setUserProperties({
              attr_channel: appsFlyer.data.af_status
            });
          }
          this.appsFlyerInitialized = true;
          console.log('AppsFlyer SDK initialized');
        }
      }
    } catch (e) {
      console.log('Tracking could not be initialized:', e);
    }

    return Promise.resolve();
  }

  private startSession(sessionKey: string) {
    if (this.isBrowser) {
      return;
    }

    if (!this.sessions.hasOwnProperty(sessionKey)) {
      this.sessions[sessionKey] = [];
    }
    this.sessions[sessionKey].push({
      start: new Date()
    });

    if (!this.sessionCount.hasOwnProperty(sessionKey)) {
      this.sessionCount[sessionKey] = 0;
    }
    this.sessionCount[sessionKey]++;
    this.storageService.save(Variables.Storage.SessionCount, this.sessionCount);
  }

  private stopSession(sessionKey: string): Session {
    if (this.isBrowser) {
      return;
    }

    if (!this.sessions.hasOwnProperty(sessionKey)) {
      return null;
    }

    this.sessions[sessionKey][this.sessions[sessionKey].length - 1].end = new Date();
    const session: Session = {
      sessions: this.sessions[sessionKey],
      start: moment(this.sessions[sessionKey][0].start).format(),
      total: this.sessionCount[sessionKey]
    };

    let actualDifference = 0;
    this.sessions[sessionKey]
      .filter(session => !!session.start && !!session.end)
      .forEach(s => {
        if (typeof s.start === 'string') {
          s.start = new Date(s.start);
        }
        if (typeof s.end === 'string') {
          s.end = new Date(s.end);
        }
        actualDifference += (s.end.getTime() - s.start.getTime());
      });
    session.actualDifference = actualDifference;

    delete this.sessions[sessionKey];
    return session;
  }

  /**
   * User sessions
   */
  async triggerSessionStart() {
    console.log('Analytics: session_start');
    if (this.isBrowser) {
      return;
    }

    const profile: AppProfile = this.profileService.appProfile;
    const sessions = this.sessionCount[this.EVENTS.Firebase.SessionStart];
    this.logEventWithStrings(this.EVENTS.Firebase.SessionStart, {
      push_folders: profile.notifications_enabled ? 1 : 0,
      push_system: profile.notifications_enabled ? 1 : 0,
      session_count: sessions ? sessions + 1 : 1,
      session_id: this.profileService.sessionId
    });
    this.startSession(this.EVENTS.Firebase.SessionStart);

    await this.initializeTracking();

    // AppsFlyer Stats
    this.appsFlyer.trackEvent(this.EVENTS.AppsFlyer.SessionStart, {});
  }

  triggerSessionEnd() {
    console.log('Analytics: session_end');
    if (this.isBrowser) {
      return;
    }

    const session = this.stopSession(this.EVENTS.Firebase.SessionStart);
    this.logEventWithStrings(this.EVENTS.Firebase.SessionEnd, {
      session_count: session.total,
      session_id: this.profileService.sessionId,
      session_timestamp: session.start,
      session_span: session.actualDifference
    });
  }

  /**
   * End user sessions
   */

  /**
   * Trigger folder opened
   */
  async triggerFolderOpen(folder: Folder, sessionId: string) {
    console.log('Analytics: folder_open');
    if (this.isBrowser) {
      return;
    }

    await this.initializeTracking();

    // Stats Receiver
    const profile: AppProfile = this.profileService.appProfile,
      location = this.locationService.location;
    if (this.platform.is('cordova') && profile.app_platform && profile.app_version && profile.device_id) {
      this.apiService.sendStats(this.statsUrl, {
        app_platform: profile.app_platform,
        appVersion: profile.app_version,
        app_version: profile.app_version,
        billable: !this.folderOpenedInThisSession[folder.folder_version_id],
        device_category: profile.device_category,
        device_id: profile.device_id,
        folder_session_id: sessionId,
        folder_version_id: folder.folder_version_id,
        os: profile.app_platform,
        retailer_id: folder.retailer_id,
        stored_zip: location && location.postalCode ? location.postalCode : '',
        type: 'folder_view'
      }).toPromise();
    }

    // AppsFlyer Stats
    this.appsFlyer.trackEvent(this.EVENTS.AppsFlyer.FolderOpened, {
      af_content_type: 'rf_folder',
      af_content_id: folder.folder_version_id,
      af_price: 0.01,
      af_quantity: 1,
      af_currency: 'EUR',
      af_param_2: folder.retailer_name,
      af_param_3: folder.retailer_id
    });

    // Firebase Stats
    this.startSession(`${this.EVENTS.Firebase.FolderView}_${folder.folder_version_id}`);
    this.logEventWithStrings(this.EVENTS.Firebase.FolderOpen, {
      billable: this.folderOpenedInThisSession[folder.folder_version_id] ? 0 : 1,
      folder_page_count: folder.pages.length,
      folder_session_id: sessionId,
      folder_version_id: folder.folder_version_id,
      retailer_category: folder.category_name,
      retailer_id: folder.retailer_id,
      retailer_name: folder.retailer_name,
      session_id: this.profileService.sessionId,
      source: this.apiService.getScreenId()
    });

    // Additional stats on certain folder read count
    const totalFoldersRead = this.folderService.getTotalFoldersRead();
    if (totalFoldersRead === 5) {
      this.appsFlyer.trackEvent(this.EVENTS.AppsFlyer.FolderOpenedFifth, {});
    } else if (totalFoldersRead === 10) {
      this.appsFlyer.trackEvent(this.EVENTS.AppsFlyer.FolderOpenedTenth, {});
    }
  }

  triggerFolderView(folder: Folder, sessionId: string, pagesViewed: number) {
    console.log('Analytics: folder_view');
    if (this.isBrowser) {
      return;
    }

    const session = this.stopSession(`${this.EVENTS.Firebase.FolderView}_${folder.folder_version_id}`);
    this.logEventWithStrings(this.EVENTS.Firebase.FolderView, {
      billable: this.folderOpenedInThisSession[folder.folder_version_id] ? 0 : 1,
      folderview_duration: session.actualDifference,
      folderview_page_max: pagesViewed,
      folder_page_count: folder.pages.length,
      folder_session_id: sessionId,
      folder_version_id: folder.folder_version_id,
      retailer_category: folder.category_name,
      retailer_id: folder.retailer_id,
      retailer_name: folder.retailer_name,
      session_id: this.profileService.sessionId,
      source: this.apiService.getScreenId(),
      timestamp_start: session.start
    });

    // Folder opened in this session
    this.folderOpenedInThisSession[folder.folder_version_id] = true;
  }

  /**
   * End trigger folder view
   */

  /**
   * Trigger folder page open
   */
  triggerFolderPageOpen(folder: Folder, sessionId: string, currentPages: any) {
    console.log('Analytics: folder_page_open');
    if (this.isBrowser) {
      return;
    }

    currentPages.forEach(pageId => {
      this.startSession(`${this.EVENTS.Firebase.PageView}_${folder.folder_version_id}_p${pageId}`);
      this.logEventWithStrings(this.EVENTS.Firebase.PageOpen, {
        page_id: pageId,
        folder_page_count: folder.pages.length,
        folder_session_id: sessionId,
        folder_version_id: folder.folder_version_id,
        retailer_category: folder.category_name,
        retailer_id: folder.retailer_id,
        retailer_name: folder.retailer_name,
        session_id: this.profileService.sessionId,
        source: this.apiService.getScreenId()
      });
    });
  }

  triggerFolderPageView(folder: Folder, sessionId: string, currentPages: any) {
    console.log('Analytics: folder_page_view');
    if (this.isBrowser) {
      return;
    }

    currentPages.forEach(pageId => {
      const session = this.stopSession(`${this.EVENTS.Firebase.PageView}_${folder.folder_version_id}_p${pageId}`);
      if (session !== null) {
        this.logEventWithStrings(this.EVENTS.Firebase.PageView, {
          page_id: pageId,
          folder_page_count: folder.pages.length,
          folder_session_id: sessionId,
          folder_version_id: folder.folder_version_id,
          pageview_duration: Math.floor(session.actualDifference / currentPages.length),
          retailer_category: folder.category_name,
          retailer_id: folder.retailer_id,
          retailer_name: folder.retailer_name,
          session_id: this.profileService.sessionId,
          source: this.apiService.getScreenId(),
          timestamp_start: session.start
        });
      }
    });
  }

  /**
   * Trigger offer opened
   */
  async triggerOfferOpened(offer: Offer, folderSession?: string, folder?: Folder) {
    console.log('Analytics: offer_opened');
    if (this.isBrowser) {
      return;
    }

    await this.initializeTracking();
    const sessionId = this.profileService.sessionId;

    // AppsFlyer Stats
    this.appsFlyer.trackEvent(this.EVENTS.AppsFlyer.OfferOpened, {});

    // Firebase Stats
    let firebaseEvent = {
      offer_id: offer.offer_id,
      offer_title: offer.title,
      offer_type: offer.kind,
      session_id: sessionId,
      source: this.apiService.getScreenId()
    };
    if (offer.primary_page_id) {
      firebaseEvent = Object.assign(firebaseEvent, {
        folder_session_id: folderSession,
        folder_version_id: folder ? folder.folder_version_id : offer.folder_version_id,
        page_id: offer.primary_page_id,
        retailer_category: offer.category_name,
        retailer_id: offer.retailer_id,
        retailer_name: offer.retailer_name,
      });
    }
    this.logEventWithStrings(this.EVENTS.Firebase.OfferOpen, firebaseEvent);

    // Additional stats on certain offer read count
    const totalOffersOpened = this.offerService.getTotalOffersRead();
    if (totalOffersOpened === 5) {
      this.appsFlyer.trackEvent(this.EVENTS.AppsFlyer.OfferOpenedFifth, {});
    } else if (totalOffersOpened === 10) {
      this.appsFlyer.trackEvent(this.EVENTS.AppsFlyer.OfferOpenedTenth, {});
    }
  }

  /**
   * End trigger offer opened
   */

  triggerOfferOpenWebsite(offer: Offer, folderSession?: string, folder?: Folder) {
    console.log('Analytics: offer_open_website');
    if (this.isBrowser) {
      return;
    }

    const sessionId = this.profileService.sessionId;
    this.logEventWithStrings(this.EVENTS.Firebase.OfferLink, {
      folder_session_id: folderSession,
      folder_version_id: folder ? folder.folder_version_id : offer.folder_version_id,
      offer_id: offer.offer_id,
      offer_title: offer.title,
      offer_type: offer.kind,
      retailer_category: offer.category_name,
      retailer_id: offer.retailer_id,
      retailer_name: offer.retailer_name,
      session_id: sessionId
    });
  }

  triggerOfferSavedToList(offer: Offer, folderSession?: string, folder?: Folder) {
    console.log('Analytics: offer_saved');
    if (this.isBrowser) {
      return;
    }

    const sessionId = this.profileService.sessionId;
    this.logEventWithStrings(this.EVENTS.Firebase.OfferSave, {
      folder_session_id: folderSession,
      folder_version_id: folder ? folder.folder_version_id : offer.folder_version_id,
      offer_id: offer.offer_id,
      offer_title: offer.title,
      offer_type: offer.kind,
      retailer_category: offer.category_name,
      retailer_id: offer.retailer_id,
      retailer_name: offer.retailer_name,
      session_id: sessionId
    });
  }

  triggerOfferShared(offer: Offer) {
    console.log('Analytics: offer_shared');
    if (this.isBrowser) {
      return;
    }

    const sessionId = this.profileService.sessionId;
    this.logEventWithStrings(this.EVENTS.Firebase.ContentShare, {
      destination: '',
      item_description: offer.title,
      item_id: offer.offer_id,
      item_type: 'offer',
      session_id: sessionId
    });
  }

  /**
   * External link open
   */
  triggerLinkOpen(offer?: Offer, folderSession?: string, folder?: Folder) {
    console.log('Analytics: link_open');
    if (this.isBrowser) {
      return;
    }

    const profile: AppProfile = this.profileService.appProfile;
    this.logEventWithStrings(this.EVENTS.Firebase.LinkOpen, {
      app_platform: profile.app_platform,
      billable: 0,
      destination: offer ? 'product' : 'website',
      folder_session_id: folderSession,
      folder_version_id: folder ? folder.folder_version_id : offer ? offer.folder_version_id : '',
      item_id: offer ? offer.offer_id : '',
      item_description: offer ? offer.description : '',
      page_id: offer ? offer.primary_page_id : '',
      retailer_category: offer ? offer.category_name : '',
      retailer_id: offer ? offer.retailer_id : '',
      retailer_name: offer ? offer.retailer_name : '',
      session_id: this.profileService.sessionId,
      source: this.apiService.getScreenId()
    });
  }

  /**
   * Search trigger
   */
  triggerSearchQuery(searchQuery: string, offer?: Offer, retailer?: Retailer, folder?: Folder) {
    console.log('Analytics: search_query');
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.UserSearchQuery, {
      destination: offer ? offer.title : retailer ? retailer.retailer_name : folder ? folder.retailer_name : '',
      folder_version_id: folder ? folder.folder_version_id : offer ? offer.folder_version_id : '',
      offer_id: offer ? offer.offer_id : '',
      retailer_id: offer ? offer.retailer_id : retailer ? retailer.retailer_id : '',
      retailer_name: offer ? offer.retailer_name : retailer ? retailer.retailer_name : '',
      search_string: searchQuery,
      session_id: this.profileService.sessionId
    });
  }

  /**
   * Trigger on splash screen
   */
  triggerConfigRefreshed() {
    console.log('Analytics: config_refreshed');
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.ConfigRefresh, {});
  }

  triggerTabOpen(tab: string) {
    console.log(`Analytics: ${this.EVENTS.Firebase.TabOpen}`);
    if (this.isBrowser) {
      return;
    }

    const tabValues = {
      folders: 'home',
      search: 'search',
      lists: 'shopping_list',
      cards: 'loyalty_cards',
      settings: 'settings'
    };
    this.logEventWithStrings(this.EVENTS.Firebase.TabOpen, {
      session_id: this.profileService.sessionId,
      tab_description: tabValues[tab]
    });
  }

  triggerTeaserOpen(title: string, position: 'single' | 'double', index?: number) {
    console.log(`Analytics: ${this.EVENTS.Firebase.TeaserOpen}`);
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.TeaserOpen, {
      teaser_position: `${position} ${index}`,
      teaser_title: title,
      session_id: this.profileService.sessionId
    });
  }

  triggerKeywordOpen(keyword: string) {
    console.log(`Analytics: ${this.EVENTS.Firebase.KeywordOpen}`);
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.KeywordOpen, {
      keyword_permaname: keyword,
      session_id: this.profileService.sessionId
    });
  }

  triggerCategoryOpen(category: string) {
    console.log(`Analytics: ${this.EVENTS.Firebase.CategoryOpen}`);
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.CategoryOpen, {
      category_permaname: category,
      session_id: this.profileService.sessionId
    });
  }

  triggerRetailerOpen(retailer: string) {
    console.log(`Analytics: ${this.EVENTS.Firebase.RetailerOpen}`);
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.RetailerOpen, {
      retailer_permaname: retailer,
      session_id: this.profileService.sessionId,
      source: this.apiService.getScreenId()
    });
  }

  triggerRetailerFavoriteToggle(retailer: string, enabled: boolean) {
    console.log(`Analytics: ${this.EVENTS.Firebase.RetailerFavoriteToggle}`);
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.RetailerFavoriteToggle, {
      retailer_permaname: retailer,
      session_id: this.profileService.sessionId,
      source: this.apiService.getScreenId(),
      enabled: enabled ? 1 : 0
    });
  }

  triggerSaveKeywordToList(keyword: string) {
    console.log(`Analytics: ${this.EVENTS.Firebase.KeywordSavedToList}`);
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.KeywordSavedToList, {
      query_text: keyword,
      session_id: this.profileService.sessionId
    });
  }

  triggerOpenLoyaltyCard(retailer: string) {
    console.log(`Analytics: ${this.EVENTS.Firebase.LoyaltyCardOpen}`);
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.LoyaltyCardOpen, {
      retailer_permaname: retailer,
      session_id: this.profileService.sessionId
    });
  }

  triggerSaveLoyaltyCard(retailer: string) {
    console.log(`Analytics: ${this.EVENTS.Firebase.LoyaltyCardSave}`);
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.LoyaltyCardSave, {
      retailer_permaname: retailer,
      session_id: this.profileService.sessionId
    });
  }

  setUserLocationSource(source: 'home' | 'settings') {
    this.userLocationSource = source;
  }

  triggerSaveLocation(postalCode: string) {
    console.log(`Analytics: ${this.EVENTS.Firebase.UserLocationSave}`);
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.UserLocationSave, {
      postal_code: postalCode,
      session_id: this.profileService.sessionId,
      source: this.userLocationSource
    });
  }

  setNotificationSource(source: 'settings' | 'retailer' | 'folder') {
    this.notificationSource = source;
  }

  triggerSaveNotifications(enabled: boolean, topic?: string) {
    console.log(`Analytics: ${this.EVENTS.Firebase.NotificationsSaved}`);
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.NotificationsSaved, {
      enabled: enabled ? '1' : '0',
      session_id: this.profileService.sessionId,
      source: this.notificationSource,
      topic: topic ? topic : 'Nieuwe folder',
      topic_name: topic ? topic : 'Nieuwe folder'
    });
  }

  triggerLoadMore(section: string, title: string) {
    console.log(`Analytics: ${this.EVENTS.Firebase.LoadMore}`);
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.LoadMore, {
      section_id: section,
      section_title: title,
      session_id: this.profileService.sessionId,
      source: this.apiService.getScreenId()
    });
  }

  triggerOverviewLink(type: string, title: string) {
    console.log(`Analytics: ${this.EVENTS.Firebase.OverviewLink}`);
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.OverviewLink, {
      section_id: type,
      section_title: title,
      session_id: this.profileService.sessionId
    });
  }

  triggerOverviewCard(type: string, title: string) {
    console.log(`Analytics: ${this.EVENTS.Firebase.OverviewCard}`);
    if (this.isBrowser) {
      return;
    }

    this.logEventWithStrings(this.EVENTS.Firebase.OverviewCard, {
      section_id: type,
      section_title: title,
      session_id: this.profileService.sessionId
    });
  }

  setUserProperties(props: AnyKey) {
    for (const prop in props) {
      if (props.hasOwnProperty(prop)) {
        this.profileService.setProfileProperty(prop, props[prop]);
        this.firebaseAnalytics.setUserProperty(prop, props[prop]);
      }
    }
  }

  setUserPropertyThemeNotifications(bool: boolean) {
    this.firebaseAnalytics.setUserProperty('theme_notifications', bool.toString());
  }

  async enableFirebase(enabled: boolean) {
    await this.firebaseAnalytics.setAnalyticsCollectionEnabled(enabled);
  }

  private checkEndOfSession(initFromRestartApp: boolean = false) {
    if (this.sessions.hasOwnProperty(this.EVENTS.Firebase.SessionStart) && !initFromRestartApp) {
      const session = this.sessions[this.EVENTS.Firebase.SessionStart],
        pauseTime = session[session.length - 1].end,
        now = new Date();

      if (Math.ceil(moment.duration(moment(now).diff(pauseTime)).asMinutes()) >= 15) {
        this.triggerSessionEnd();
        this.profileService.reInitSession();
        this.triggerSessionStart();
        this.triggerConfigRefreshed();
      } else {
        this.sessions[this.EVENTS.Firebase.SessionStart].push({
          start: new Date()
        });
      }
    }

    if (initFromRestartApp) {
      const keysWithoutUserSession = Object.keys(this.sessions).filter(s => s !== this.EVENTS.Firebase.SessionStart);
      keysWithoutUserSession.forEach(session => delete this.sessions[session]);
      this.storageService.save(Variables.Storage.Sessions, this.sessions);
    }
  }

  private logEventWithStrings(event: string, data: AnyKey) {
    const profile: AppProfile = this.profileService.appProfile;
    if (!profile) {
      return;
    }
    data = {
      ...data,
      device_id: profile.device_id,
      stored_city: profile.stored_city,
      stored_loc_src: profile.stored_loc_src,
      stored_zip: profile.stored_zip,
      privacystatement_version: profile.privacystatement_version
    };

    if (profile.attr_channel && profile.attr_campaign) {
      data = {
        ...data,
        attr_channel: profile.attr_channel,
        attr_campaign: profile.attr_campaign
      };
    }

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (!isNaN(data[key])) {
          data[key] = data[key].toString();
        } else if (data[key] === undefined) {
          delete data[key];
        }
      }
    }

    console.log(`EVENT ${event}`, data);
    return this.firebaseAnalytics.logEvent(event, data);
  }
}
