import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Button, Folder, Offer} from '../../../constants/interfaces';
import { Section } from '../section';
import { ActivatedRoute, Router } from '@angular/router';
import { Buttons } from '../../../constants/enums';
import { ModalController } from '@ionic/angular';
import {InternalRoutingService} from '../../../services/internal-routing.service';
import {OfferService} from '../../../services/offer.service';
import {TrackingService} from '../../../services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { ListService } from '../../../services/list.service';

@Component({
  selector: 'app-offer-detail-section',
  templateUrl: './offer-detail-section.component.html',
  styleUrls: ['./offer-detail-section.component.scss'],
})
export class OfferDetailSectionComponent extends Section<Offer> implements OnInit {

  offer: Offer;
  inFolder: boolean;
  buttons: {
    [key: string]: Button;
  };

  @Input()
  folderSession?: string;
  @Input()
  folder?: Folder;

  @Output()
  imageEvents: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private internalRouting: InternalRoutingService,
              private translateService: TranslateService,
              private listService: ListService,
              private offerService: OfferService,
              private trackingService: TrackingService,
              public modalCtrl: ModalController) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.inFolder = this.router.url.indexOf('/folder/') > -1;
    this.offer = this.items[0];
    this.buttons = this.config.buttons;

    this.offerService.readOffer(this.offer);
    this.trackingService.triggerOfferOpened(this.offer, this.folderSession, this.folder);

  }


  clickedRetailerRedirect() {
    this.trackingService.triggerOfferOpenWebsite(this.offer, this.folderSession, this.folder);
    this.trackingService.triggerLinkOpen(this.offer, this.folderSession, this.folder);
    window.open(!!this.offer.deeplink_redirect_url ? this.offer.deeplink_redirect_url : this.offer.retailer_redirect_url, '_system');
  }

  executeAction(button: Button, key: string) {
    this.internalRouting.handleRouting(button, {
      offer: this.offer,
      folder: this.folder,
      folderSession: this.folderSession,
      saved: key === Buttons.SAVE_TO_LIST ? this.listService.getOfferFromLists(this.offer) : false
    });
  }

  isInFolder(buttonType: string): boolean {
    return buttonType.toString() === Buttons.OPEN_FOLDER ? this.inFolder : false;
  }

  getBtnText(key: string, button: Button) {
    let btnText: string;

    if (key === Buttons.SAVE_TO_LIST) {
      const offerExistsInList = this.listService.getOfferFromLists(this.offer);

      if (offerExistsInList) {
        this.translateService.get('global.saved').subscribe(translation => btnText = translation);
      } else {
        btnText = button.label;
      }
    } else {
      btnText = button.label;
    }
    return btnText;
  }
}
