import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnyKey} from '../../constants/interfaces';
import {environment} from '../../../environments/environment';
import {InternalRoutingService} from '../../services/internal-routing.service';
import {Platform} from '@ionic/angular';
import {AdvertisementService} from '../../services/advertisement.service';
import { filter, first } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementComponent implements OnInit {

  @Input()
  adUnit: string;

  @Input()
  width: number = 300;
  @Input()
  height: number = 250;

  @Input()
  targeting?: AnyKey = {};

  @Input()
  triggerLoad$: Subject<boolean>;

  @Output()
  onRendered: EventEmitter<void> = new EventEmitter();

  readonly baseAdUnit = environment.baseAdUnit;

  completeAdUnit: string;
  adScale: number = 1;

  constructor(private elementRef: ElementRef,
              private internalRouting: InternalRoutingService,
              // private advertisementService: AdvertisementService,
              private platform: Platform) {
  }

  ngOnInit() {

    // const adTrackingInfo = this.advertisementService.adTrackingInfo;
    //
    // if (adTrackingInfo.rdid !== null) {
    //   this.targeting = {
    //     ...this.targeting,
    //     ...adTrackingInfo,
    //   };
    // }

    // this.targeting.app_name= 'ionic';

    if (this.triggerLoad$) {
      this.triggerLoad$.pipe(
        filter(show => show),
        first()
      ).subscribe(() => {
        this.doLoad();
      });
    } else {
      this.doLoad();
    }
  }

  private doLoad() {
    this.completeAdUnit = this.baseAdUnit +
      (this.platform.is('ios') ? 'ios' : 'android') + '/' + this.adUnit;
  }


  afterRefresh(event) {

    if (event.type === 'renderEnded') {

      // console.log(event.slot);
      // console.log(event.slot.getClickUrl());
      // console.log(event.slot.getContentUrl());
      // console.log(event.slot.getSlotElementId());

      // const elemId = event.slot.getSlotElementId()
      // const iframe = document.getElementById(elemId).querySelectorAll('iframe')[0];
      // console.log(iframe);
      // iframe.sandbox.remove('allow-top-navigation-by-user-activation',
      //   'allow-top-navigation');
      // // reload?
      //
      // iframe.src = iframe.src;
      //
      // console.log(iframe.getAttribute('sandbox'));
      // iframe.setAttribute('sandbox', 'allow-scripts');

      this.setAdScale(event.data);
      this.onRendered.emit();
    }
  }

  private setAdScale(renderData) {
    if (!renderData.size) {
      return;
    }
    const widthScale = this.width / renderData.size[0];
    const heightScale = this.height / renderData.size[1];
    this.adScale = widthScale < heightScale ? widthScale : heightScale;
  }
}
