import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressiveBackgroundImgDirective } from './progressive-background-img.directive';
import { ProgressiveImgDirective } from './progressive-img.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ProgressiveBackgroundImgDirective, ProgressiveImgDirective],
  exports: [ProgressiveBackgroundImgDirective, ProgressiveImgDirective]
})
export class ProgressiveImgModule {
}
