import {Injectable} from '@angular/core';
import {Network} from '@ionic-native/network/ngx';
import {RFError} from '../constants/enums';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessagesService {

  private isConnected: boolean = true;

  reConnect = new BehaviorSubject(null);

  constructor(private network: Network) {
    this.isConnected = this.network.type !== this.network.Connection.NONE &&
      this.network.type !== this.network.Connection.UNKNOWN;
    this.network.onConnect()
      .subscribe(() => {
        this.isConnected = true;
        this.reConnect.next(true);
      });
    this.network.onDisconnect()
      .subscribe(() => {
        this.isConnected = false;
        this.reConnect.next(false);
      });
  }

  getErrorMessage(errorCode: RFError) {
    let message;
    switch (errorCode) {
      case RFError.ERROR_SCREEN_NOT_FOUND:
        message = 'error.screenNotFoundDescription';
        break;
      case RFError.ERROR_INIT:
        message = 'error.initDescription';
        break;
      case RFError.ERROR_GENERAL:
        message = 'error.generalDescription';
        break;
      case RFError.ERROR_CONTACT:
        message = 'opinion.errorMessage';
        break;
    }

    if (!this.isConnected) {
      message = 'error.networkDescription';
    }

    return {
      message,
      connected: this.isConnected
    };
  }

}
