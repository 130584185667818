import { Injectable } from '@angular/core';
import { Retailer } from '../constants/interfaces';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';
import { Variables } from '../constants/variables';
import { InteractionService } from './interaction.service';
import { ApiService } from './api.service';
import {NotificationsService} from "./notifications.service";
import {take} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class NotificationsRetailerService {

    private retailers: BehaviorSubject<Retailer[]> = new BehaviorSubject([]);

    get getNotifications() {
        return this.retailers.asObservable();
    }

    get asIdArray(): number[] {
        const retailers = this.retailers.getValue();
        return retailers.map(f => f.retailer_id);
    }

    constructor(private storageService: StorageService,
                private interactionService: InteractionService,
                private api: ApiService,
                private notificationService: NotificationsService) {
        this.initializeNotifications();
    }

    private initializeNotifications() {
        this.storageService.load(Variables.Storage.RetailersNotifications).subscribe(currentValue => {
            if (currentValue && currentValue.length > 0) {
                this.retailers.next(currentValue);
            }
        });
    }

    addToNotifications(retailers: Retailer[], showToast: boolean = true) {
        if (retailers && retailers.length > 0) {

            const currentValue = this.retailers.getValue();
            let addedRetailer: Retailer;
            retailers.forEach(r => {
                if (currentValue.findIndex(c => c.retailer_id === r.retailer_id) === -1) {
                    currentValue.push(r);
                    addedRetailer = r;
                    this.notificationService.subscribeTo('production-retailer_'+r.retailer_id);
                }
            });
            currentValue.sort((a, b) => (a.retailer_name > b.retailer_name) ? 1 : ((b.retailer_name > a.retailer_name) ? -1 : 0));
            this.retailers.next(currentValue);
            this.saveInStorage(currentValue);
            if (showToast && addedRetailer) {
                this.interactionService.showToast('notifications.addedToast', {retailer: addedRetailer.retailer_name});
            }
        }
    }

    removeFromNotifications(retailer: Retailer, showToast: boolean = true) {
        const currentValue = this.retailers.getValue();
        const index = currentValue.findIndex(r => r.retailer_id === retailer.retailer_id);
        const removedRetailer = currentValue[index];
        if (index !== -1) {
            currentValue.splice(index, 1);
            this.retailers.next(currentValue);
            this.notificationService.unsubscribeFrom('production-retailer_'+removedRetailer.retailer_id);
        }
        this.saveInStorage(currentValue);
        if (showToast) {
            this.interactionService.showToast('notifications.removedToast', {retailer: removedRetailer.retailer_name});
        }
    }

    hasNotificationsEnabled(retailerId: number) {
        return this.asIdArray.includes(retailerId);
    }

    toggle(retailer: Retailer) {
        if (this.hasNotificationsEnabled(retailer.retailer_id)) {
            this.removeFromNotifications(retailer);
        } else {
            this.addToNotifications([retailer]);
        }
    }

    private saveInStorage(value: Retailer[]) {
        this.storageService.save(Variables.Storage.RetailersNotifications, value);
    }

    getCurrentList(){
        return new Promise(resolve => {
            this.storageService.load(Variables.Storage.RetailersNotifications).subscribe(currentValue => {
                if (currentValue && currentValue.length > 0) {
                    resolve(currentValue);
                }
            });
        });
    }
}
