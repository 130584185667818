import {Component, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';
import {Platform} from '@ionic/angular';
import {LocationService} from '../../services/location.service';
import {Location} from '../../constants/interfaces';

declare var google;

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit {

  map: any;
  marker: any;
  geocoder: any;

  location: Location;

  @Output()
  onFinishedLoading = new EventEmitter<void>();

  constructor(private platform: Platform,
              private element: ElementRef,
              private locationService: LocationService) {
  }

  async ngOnInit() {
    await this.locationService.initGoogle();
    await this.loadGoogleMap();
  }

  public setLocation(location: Location, locationIsSet: boolean = true): void {
    this.location = location;
    this.zoomToCurrentLocation(locationIsSet ? 14 : 7, locationIsSet);
  }

  private zoomToCurrentLocation(zoom: number, showMarker: boolean) {
    if (this.map !== undefined) {
      this.map.setCenter(new google.maps.LatLng(this.location.latitude, this.location.longitude));
      this.map.setZoom(zoom);
      if (showMarker) {
        this.resetMarker();
      } else if (this.marker) {
        this.marker.setMap(null);
      }
    }
  }

  private resetMarker() {
    if (this.marker) {
      this.marker.setMap(null);
    }
    this.marker = new google.maps.Marker({
      position: {
        lat: this.location.latitude,
        lng: this.location.longitude
      },
      map: this.map,
      icon: {
        url: './assets/icons/map-pin.svg',
        scaledSize: new google.maps.Size(50, 50),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(25, 50)
      }
    });
    this.marker.setMap(this.map);
  }

  /**
   * Google JS SDK
   */
  private async loadGoogleMap() {
    let zoom, setMarker;
    if (!this.location) {
      this.location = {
        latitude: 51.822606,
        longitude: 5.8647082,
        postalCode: ''
      };
      zoom = 7;
    } else {
      zoom = 14;
      setMarker = true;
    }
    const latLng = new google.maps.LatLng(this.location.latitude, this.location.longitude);

    const mapOptions = {
      center: latLng,
      zoom,
      disableDefaultUI: true
    };

    this.map = new google.maps.Map(this.element.nativeElement, mapOptions);
    this.geocoder = new google.maps.Geocoder();

    if (setMarker) {
      this.resetMarker();
    }
    this.onFinishedLoading.emit();
  }

}
