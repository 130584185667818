import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferModalComponent } from './offer-modal.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import {OfferDetailSectionModule} from '../sections/offer-detail-section/offer-detail-section.module';
import {ErrorMessageModule} from '../error-message/error-message.module';
import {ScreenSectionsModule} from '../screen-sections/screen-sections.module';

@NgModule({
  declarations: [
    OfferModalComponent
  ],
  exports: [
    OfferModalComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule.forChild(),
    OfferDetailSectionModule,
    ScreenSectionsModule,
    ErrorMessageModule
  ],
  entryComponents: [
    OfferModalComponent
  ]
})
export class OfferModalModule { }
