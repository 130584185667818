import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { Variables } from '../constants/variables';
import {from, Observable} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import { Cookie } from '../constants/interfaces';
import {TrackingService} from './tracking.service';
import {PreferencesService} from './preferences.service';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor(private storageService: StorageService,
              private trackingService: TrackingService,
              private preferencesService: PreferencesService) { }

  isCookieAccepted(): Observable<boolean> {
    let hasAcceptedCookiesInNativeApp = null;
    return from(this.preferencesService.getPreference(Variables.NativeApp.UserPreferences.CookieStatementVersion))
      .pipe(
        tap(value => hasAcceptedCookiesInNativeApp = value),
        switchMap(() => this.storageService.load(Variables.Storage.Cookie)),
        map(cookie => {
          if (hasAcceptedCookiesInNativeApp) {
            this.saveCookie();
            return true;
          }
          return !!(cookie && cookie.enabled);
        })
      );
  }

  async saveCookie() {
    const cookie: Cookie = {
      privacystatement_version: 3,
      enabled: true
    };
    this.storageService.save(Variables.Storage.Cookie, cookie);

    await this.trackingService.enableFirebase(true);
    this.trackingService.triggerSessionStart();
    this.trackingService.setUserProperties({
      privacystatement_version: cookie.privacystatement_version,
      privacy_statement_version: cookie.privacystatement_version
    });
  }
}
