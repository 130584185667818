import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Pipe({
    name: 'euro'
})
export class EuroPipe extends CurrencyPipe implements PipeTransform {

    transform(value: any, ...args): string {
        if (!value) {
            return value;
        }
        const showSymbol = args[0],
            asHtml = args[1],
            euro = super.transform(value, showSymbol ? 'EUR' : '', showSymbol ? '€' : '', '1.2-2', 'nl');
        if (!asHtml) {
            return euro;
        } else {
            const splitted = euro.split(',');
            return splitted[0] + ',' + '<small><sup>' + splitted[1] + '</sup></small>';
        }
    }

}
